import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Box, Button, TextField, makeStyles } from '@material-ui/core';

import * as auth from '~/store/auth';
import { authRoles } from '~/constants';
import Page from '~/components/shared/Page';
import {
  loginWithEmail,
  loginWithSession,
  loginWithGoogle,
  magic,
} from '~/services/magic';

import { Google } from '~/views/private/overview/Components/icons/Google';
import { ArrowRight } from '~/views/private/overview/Components/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  shadowBox: {
    width: '400px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow:
      '0px 36px 80px 0px rgba(23, 28, 38, 0.05), 0px 13.141px 29.201px 0px rgba(23, 28, 38, 0.03), 0px 6.38px 14.177px 0px rgba(23, 28, 38, 0.03), 0px 3.127px 6.95px 0px rgba(23, 28, 38, 0.02), 0px 1.237px 2.748px 0px rgba(23, 28, 38, 0.02)',
    borderRadius: 'var(--utilities-border-radius-xl)',
    backgroundColor: 'var(--surface-default)',
  },
  topContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-500)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-3-000)',
    alignSelf: 'stretch',
  },
  arrowImg: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
    marginLeft: 'var(--utilities-size-0-500)',
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
  },
  header: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  input: {
    '& .MuiInputBase-formControl': {
      width: '352px',
      height: 'var(--utilities-size-2-500)',
      minHeight: 'var(--utilities-size-2-500)',
      border: '1px',
      borderRadius: 'var(--utilities-border-radius-default)',
      backgroundColor: 'var(--surface-default)',
      padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
      fontFamily: 'var(--typography-font-family-default) !important',
      fontSize: 'var(--typography-font-size-md)',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'var(--typography-line-height-md-line)',
      letterSpacing: 'var(--typography-letter-spacing-default)',
      color: 'var(--content-primary)',
      marginTop: '-4px',
    },
    '& .MuiInputLabel-formControl': {
      fontSize: 'var(--typography-font-size-md)',
      fontWeight: '400',
      lineHeight: 'var(--typography-line-height-md-line)',
      marginTop: '-16px',
      color: 'var(--content-primary-weak)',
    },
  },
  form: {
    '& .MuiFormControl-marginNormal': {
      marginTop: 'var(--utilities-size-none)',
    },
  },
  errorTextContainer: {
    display: 'flex',
    padding:
      'var(--utilities-size-0-500) var(--utilities-size-0-500) var(--utilities-size-0-500) var(--utilities-size-1-000)',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-danger-weak)',
    background: 'var(--surface-danger-tint)',
    marginBottom: 'var(--utilities-size-1-500)',
  },
  errorText: {
    color: 'var(--content-danger)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  continueButton: {
    width: '352px',
    height: 'var(--utilities-size-2-500)',
    minHeight: 'var(--utilities-size-2-500)',
    marginTop: 'var(--utilities-size-0-500)',
    backgroundColor: 'var(--surface-accent)',
    border: '1px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 'var(--utilities-border-radius-default)',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'var(--surface-hover-accent-hover)',
    },
    '&:disabled': {
      backgroundColor: 'var(--surface-accent)',
      color: 'var(--content-inverted-weak) !important',
      cursor: 'not-allowed',
      pointerEvents: 'unset',
      '&:hover': {
        backgroundColor: 'var(--surface-accent)',
      },
    },
  },
  continueButtonText: {
    color: 'inherit',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  googleButtonContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-500)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderTop: '1px solid var(--border-default)',
    background: 'var(--surface-pale)',
    borderBottomLeftRadius: 'var(--utilities-border-radius-xl)',
    borderBottomRightRadius: 'var(--utilities-border-radius-xl)',
  },
  text: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  googleLogInButton: {
    width: '352px',
    height: 'var(--utilities-size-2-500)',
    cursor: 'pointer',
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-accent-weak)',
    background: 'var(--surface-accent-tint)',
    boxShadow: 'var(--shadows-subtle)',
    color: 'var(--content-accent)',
    '&:hover': {
      border: '1px solid var(--border-hover-accent-weak-hover)',
      background: 'var(--surface-hover-accent-tint-hover)',
    },
  },
  googleButtonText: {
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  googleImg: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  loading: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function roleToAuthRole(role) {
  return role === 'superadmin'
    ? authRoles.SUPER_ADMIN
    : role === 'admin'
    ? authRoles.ADMIN
    : authRoles.USER;
}

function MagicAuth({ children }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [hoveredGoogleButton, setHoveredGoogleButton] = useState(null);
  const proceedAuth = useCallback(
    result => {
      if (result) {
        dispatch(
          auth.login({
            ...result,
            role: roleToAuthRole(result.role),
            company: result.companyName,
          }),
        );
        setIsLoggedIn(true);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    loginWithSession()
      .then(user => {
        if (user) {
          proceedAuth(user);
          return;
        }

        return loginWithGoogle();
      })
      .then(user => {
        if (user) {
          proceedAuth(user);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [proceedAuth]);

  useEffect(() => {
    if (isLoggedIn && !redirected) {
      navigate('/overview', { replace: true });
      setRedirected(true);
    }
  }, [isLoggedIn, navigate, redirected]);

  if (loading) {
    return <Box className={classes.loading}>Loading...</Box>;
  }

  if (isLoggedIn) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const invalidate = actions =>
    actions.setFieldError('email', 'Email is incorrect');

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const result = await loginWithEmail(values.email);
      if (result) {
        proceedAuth(result);
      } else {
        invalidate(actions);
      }
    } catch (e) {
      console.error(e);
      invalidate(actions);
    } finally {
      setLoading(false);
    }
  };

  const handleSocialLogin = async () => {
    setLoading(true);
    try {
      await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI: new URL('/overview', window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box className={classes.container}>
        <Box className={classes.shadowBox}>
          <Box className={classes.topContainer}>
            <img
              src={'/static/icons/seenic/seenic-logo-color-simple.svg'}
              alt="Company logo"
            />
            <Box className={classes.emailContainer}>
              <Box className={classes.header}>Welcome back!</Box>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={yup.object().shape({
                  email: yup
                    .string()
                    .email(
                      'You entered an incorrect email address. Please enter a real address.',
                    )
                    .max(50),
                })}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                }) => (
                  <form className={classes.form} onSubmit={handleSubmit}>
                    {errors.email && (
                      <Box className={classes.errorTextContainer}>
                        <Box className={classes.errorText}>{errors.email}</Box>
                      </Box>
                    )}
                    <TextField
                      className={classes.input}
                      error={Boolean(errors.email)}
                      fullWidth
                      helperText={null}
                      // label="Enter your email address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <Button
                      className={classes.continueButton}
                      color="primary"
                      disabled={errors.email}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      <Box className={classes.continueButtonText}>Continue</Box>
                      <ArrowRight
                        className={classes.arrowImg}
                        color={
                          errors.email
                            ? 'var(--content-inverted-weak)'
                            : 'var(--surface-default)'
                        }
                      />
                    </Button>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
          <Box className={classes.googleButtonContainer}>
            <Box className={classes.text}>or use your account</Box>
            <Box
              className={classes.googleLogInButton}
              onClick={handleSocialLogin}
              onMouseEnter={() => setHoveredGoogleButton(true)}
              onMouseLeave={() => setHoveredGoogleButton(null)}
            >
              <Google
                className={classes.googleImg}
                color={
                  hoveredGoogleButton
                    ? 'var(--content-hover-accent-hover)'
                    : 'var(--content-accent)'
                }
              />
              <Box
                className={classes.googleButtonText}
                style={{
                  color: hoveredGoogleButton
                    ? 'var(--content-hover-accent-hover)'
                    : 'var(--content-accent)',
                }}
              >
                Continue with Google
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

MagicAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MagicAuth;
