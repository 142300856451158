/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Skeleton from 'react-loading-skeleton';

import clsx from 'clsx';
import {
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Tooltip,
  Collapse,
  IconButton,
} from '@material-ui/core';

import { authRoles } from '~/constants';
import {
  getCompanyHostingStats,
  getAllCompaniesHostingStats,
} from '~/services/api';
import { getSubscriptionById } from '~/services/stripeApi';
import { getQBInvoicesRecId } from '~/services/quickBooksApi';

import StripeDialog from '~/views/private/billings/components/StripeDialog';
import QuickBooksStatusChip from '~/views/private/billings/components/QuickBooksStatusChip';
import { LeadIcon } from '~/views/private/overview/Components/icons/LeadIcon';
import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import { PlayCircle } from '~/views/private/billings/components/icons/PlayCircle';
import { HelpCircle } from '~/views/private/billings/components/icons/HelpCircle';
import { SortCircle } from '~/views/private/billings/components/icons/SortCircle';
import { ArrowLeft } from '~/views/private/billings/components/icons/ArrowLeft';
import { ArrowRight } from '~/views/private/billings/components/icons/ArrowRight';
import { Close } from '~/views/private/billings/components/icons/Close';
import { convertUnicodeString } from '~/views/private/overview/utils';
import {
  sortByFieldName,
  dateConverter,
  capitalizeFirstLetters,
} from '../utils';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    '& .ps': {
      overflow: 'unset !important',
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },
  '.MuiList-padding': {
    paddingTop: 'var(--utilities-size-none)',
    paddingBottom: 'var(--utilities-size-none)',
  },
  tableHeaderArrowCell: {
    width: 'var(--utilities-size-2-500)',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-none)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderTopLeftRadius: 'var(--utilities-border-radius-default)',
    borderBottom: '1px solid var(--border-rude-weak)',
  },
  tableHeaderCell: {
    width: '320px',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderBottom: '1px solid var(--border-rude-weak)',
    borderTopRightRadius: 'var(--utilities-border-radius-default)',
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    textAlign: 'center',
    '& .customSelect': {
      color: 'var(--content-secondary)',
    },
    '&:hover': {
      color: 'var(--content-hover-secondary-hover)',
    },
  },
  tableRowNumberCell: {
    padding:
      'var(--utilities-size-1-000) var(--utilities-size-none) var(--utilities-size-1-000) var(--utilities-size-1-000)',
    borderBottom: '1px solid var(--border-default)',
  },
  tourNumber: {
    width: 'var(--utilities-size-1-500)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  tableCell: {
    textAlign: 'center',
    width: '320px',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  multilocationName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20rem',
    whiteSpace: 'nowrap',
    marginLeft: 'var(--utilities-size-0-500)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  tableSubRowLocation: {
    paddingLeft: 'var(--utilities-size-5-000)',
    textAlign: 'left',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  row: {
    '&:hover': {
      backgroundColor: 'var(--surface-hover-default-hover)',
    },
  },
  disabledTour: {
    backgroundColor: 'var(--surface-danger-pale)',
    '&:hover': {
      backgroundColor: 'var(--surface-danger-pale) !important',
    },
  },
  sortIcon: {
    cursor: 'pointer',
    marginLeft: 'var(--utilities-size-0-375)',
    display: 'inline',
  },
  sortIconAsc: {
    transform: 'rotate(180deg)',
  },
  leadIcon2: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
  },
  leadIcon2Rotate: {
    transform: 'rotate(90deg)',
  },
  tablePropertyCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '320px',
    whiteSpace: 'nowrap',
  },
  tablePropertyContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tablePropertyContent: {
    display: 'flex',
    alignItems: 'center',
    width: '246px',
  },
  locationsTableHead: {
    display: 'none',
  },
  loadingStyle: {
    height: '78vh',
    width: '94vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  statusCell: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  lastCell: {
    borderBottom: 'none',
  },
  statusImgContainer: {
    paddingLeft: 'var(--utilities-size-0-500)',
    borderLeft: '1px solid var(--border-default)',
  },
  sortImgContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-125)',
    marginRight: 'var(--utilities-size-0-500)',
    cursor: 'pointer',
  },
  statusImg: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  skeletonContent: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    margin: 'var(--utilities-size-1-250)',
  },
  startTourButtonBl: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-000)',
    padding: 'var(--utilities-size-0-375) var(--utilities-size-0-500)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    border: '1px solid var(--border-accent-weak)',
    background: 'var(--surface-accent-tint)',
  },
  startTourButtonGr: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-000)',
    padding: 'var(--utilities-size-0-375) var(--utilities-size-0-500)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    border: '1px solid var(--border-success-weak)',
    background: 'var(--surface-success-tint)',
  },
  formControl: {
    position: 'absolute',
    top: '0',
    right: 'var(--utilities-size-2-000)',
  },
  logoImg: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    display: 'inline-block',
    marginLeft: 'var(--utilities-size-0-500)',
  },
  menuItem: {
    '&.Mui-selected': {
      backgroundColor: '#999999 !important',
    },
    '&:hover': {
      backgroundColor: '#777777 !important',
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    height: 'var(--utilities-size-3-500)',
    backgroundColor: 'var(--surface-default)',
    marginTop: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  footerPart1: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius:
      'var(--utilities-border-radius-default) var(--utilities-border-radius-none) var(--utilities-border-radius-none) var(--utilities-border-radius-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
  footerPart2: {
    display: 'flex',
    width: '160px',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
  },
  pagination: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  footerPart3: {
    display: 'flex',
    padding: 'var(--utilities-size-none) var(--utilities-size-1-500)',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    justifyContent: 'flex-Start',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default) var(--utilities-border-radius-none)',
    borderLeft: '1px solid var(--border-default)',
    background: 'var(--surface-default)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
  },
  footerNoRec: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
    height: 'var(--utilities-size-3-500)',
    backgroundColor: 'var(--surface-default)',
    marginTop: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  selectContainer: {
    right: 'var(--utilities-size-0-500)',
    position: 'absolute',
    display: 'inline-block',
  },
  customSelect: {
    fontSize: 'var(--typography-font-size-sm)',
    padding: 'var(--utilities-size-0-125) var(--utilities-size-0-500)',
    width: '88px',
    border: '1px solid #ccc',
    borderRadius: 'var(--utilities-border-radius-sm)',
    backgroundColor: 'var(--surface-default)',
    cursor: 'pointer',
    '&:focus': {
      borderColor: 'var(--surface-hover-accent-hover)',
      outline: 'none',
    },
  },
  selectArrow: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
  option: {
    '&:hover': {
      background: 'var(--surface-hover-accent-hover)',
    },
  },
  searchInput: {
    width: '100%',
    height: '28px',
    display: 'flex',
    gap: 'var(--utilities-size-00-500)',
    padding: 'var(--utilities-size-0-750) 30px',
    alignItems: 'center',
    alignSelf: 'stretch',
    border: '1px solid var(--border-control-default)',
    borderRadius: 'var(--utilities-border-radius-full)',
    boxShadow: 'var(--shadows-subtle)',
    boxSizing: 'border-box',
    backgroundImage: 'url(/static/icons/seenic/search.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'var(--utilities-size-0-500) 5px',
    backgroundSize: 'var(--utilities-size-1-000)',
    color: 'var(--content-primary)',
    '&:hover': {
      backgroundImage: 'url(/static/icons/seenic/search.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'var(--utilities-size-0-500) 5px',
      backgroundSize: 'var(--utilities-size-1-000)',
      border: '1px solid var(--border-focus)',
    },
    '&:focus, &:focus-visible': {
      backgroundImage: 'url(/static/icons/seenic/search.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'var(--utilities-size-0-500) 5px',
      backgroundSize: 'var(--utilities-size-1-000)',
      border: '1px solid var(--border-focus)',
      background: 'var(--surface-default)',
      outline: 'unset',
    },
    '&:-internal-autofill-selected': {
      backgroundImage: 'url(/static/icons/seenic/search.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'var(--utilities-size-0-500) 5px',
      backgroundSize: 'var(--utilities-size-1-000)',
    },
  },
  searchClean: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    position: 'absolute',
    top: '22px',
    right: 'var(--utilities-size-1-500)',
    cursor: 'pointer',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    width: '180px',
    maxWidth: '240px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const HostingTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const user = useSelector(state => state.auth.user);
  const isAdmin =
    user.role === authRoles.ADMIN || user.role === authRoles.SUPER_ADMIN;

  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState(false);
  const [openedItemId, setOpenedItemId] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');
  const [isSortedAsc, setIsSortedAsc] = useState(false);
  const [page, setPage] = useState(0);
  const [arrowIconColor, setArrowIconColor] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredHelpIcon, setHoveredHelpIcon] = useState(null);
  const [hoveredStatus, setHoveredStatus] = useState(null);
  const [hoveredArrowLeft, setHoveredArrowLeft] = useState(null);
  const [hoveredArrowRight, setHoveredArrowRight] = useState(null);
  const [showFormControl, setShowFormControl] = useState(false);
  const [isSortingByStatus, setIsSortingByStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const statusOrder = [
    'active',
    'pending',
    'past_due',
    'canceled',
    'trialing',
    'paused',
    'no subscription',
  ];
  const rowsPerPage = 25;

  const toggleOpenItem = (event, id) => {
    event.stopPropagation();

    if (openedItemId === id) {
      setOpenedItemId(null);
    } else {
      setOpenedItemId(id);
    }
  };

  const handleSortCircleClick = () => {
    setShowFormControl(!showFormControl);
  };

  const hostingStats = useAsync(async () => {
    return isAdmin
      ? // eslint-disable-next-line no-return-await
        await getAllCompaniesHostingStats()
      : // eslint-disable-next-line no-return-await
        await getCompanyHostingStats(user.company);
  }, [isAdmin, refresh]);

  const properties = useMemo(() => hostingStats.value || [], [hostingStats]);

  const filteredByCompanyName = useMemo(
    () => properties.sort(sortByFieldName('company_name')),
    [properties],
  );

  const handleStateChangeInPaymentDialog = async () => {
    try {
      setRefresh(prevState => !prevState);
    } catch (error) {
      console.error('Error updating the database: ', error);
    }
  };

  const getCustomerHostingStats = async property => {
    const {
      stripe_subscription_id,
      qb_recurring_transaction_id,
      qb_recurring_data_ref,
    } = property;
    const subscription = stripe_subscription_id
      ? await getSubscriptionById(stripe_subscription_id)
      : {};
    const qb = qb_recurring_data_ref
      ? await getQBInvoicesRecId(qb_recurring_data_ref)
      : {};

    return {
      ...property,
      subscription,
      qb,
      qb_recurring_transaction_id,
    };
  };

  const getTourData = async properties => {
    const results = await Promise.all(properties.map(getCustomerHostingStats));
    return results;
  };

  const transformData = data => {
    return data.flatMap(company =>
      company.properties
        .filter(property => property.locations && property.locations.length > 0)
        .map(property => ({
          ...property,
          company_name: company.company_name,
        })),
    );
  };

  useEffect(() => {
    const loadTourData = async () => {
      if (isAdmin) {
        setData(transformData(filteredByCompanyName));
      }

      if (filteredByCompanyName.length > 0 && !isAdmin && !status) {
        const tourData = await getTourData(filteredByCompanyName);
        setData(tourData);
        // setData(filteredByCompanyName);
        setStatus(true);
      }
    };
    loadTourData();
  }, [filteredByCompanyName]);

  useEffect(() => {
    if (properties.length > 0 && isAdmin) {
      setData(transformData(properties));
    }
  }, [properties]);

  const handleClearSearch = () => {
    setSearchInput('');
  };

  const handleStartTour = e => e.stopPropagation();

  const handleSortOrderChange = () => {
    setSortOrder(order => (order === 'asc' ? 'desc' : 'asc'));
    setIsSortedAsc(prevState => !prevState);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatusChange = event => {
    const status = event.target.value;
    setSelectedStatus(status);
    setIsSortingByStatus(Boolean(status));
    handleSortCircleClick();
    setPage(0);
  };

  const filterAndSortData = useCallback(
    data => {
      const filterDataByStatus = (data, status) => {
        if (!status) return data;

        return data.filter(item => {
          const itemStatus = item.hosting_status;
          if (status === 'no subscription') {
            return ![
              'active',
              'pending',
              'past_due',
              'canceled',
              'trialing',
              'paused',
            ].includes(itemStatus);
          }
          return itemStatus === status;
        });
      };

      const sortDataByStatus = (a, b, sortOrderByStatus) => {
        const statusA = a.hosting_status;
        const statusB = b.hosting_status;
        const indexA = statusOrder.indexOf(statusA);
        const indexB = statusOrder.indexOf(statusB);

        return sortOrderByStatus === 'asc' ? indexA - indexB : indexB - indexA;
      };

      const sortDataByCreationDate = (a, b, sortOrder) => {
        const dateA = new Date(a.creation_date);
        const dateB = new Date(b.creation_date);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      };

      const filteredData = filterDataByStatus(data, selectedStatus);
      return filteredData.sort((a, b) => {
        if (isSortingByStatus) {
          const statusComparison = sortDataByStatus(a, b, 'asc');
          if (statusComparison !== 0) {
            return statusComparison;
          }
        }
        return sortDataByCreationDate(a, b, sortOrder);
      });
    },
    [selectedStatus, sortOrder, isSortingByStatus],
  );

  const filteredData = useMemo(() => {
    if (!searchInput) {
      return filterAndSortData(data);
    }
    return filterAndSortData(data).filter(item =>
      item.property_name.toLowerCase().includes(searchInput.toLowerCase()),
    );
  }, [data, filterAndSortData, searchInput]);

  const paginatedData = () =>
    filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  if (hostingStats.loading) {
    return <div className={classes.loadingStyle}>Loading...</div>;
  }

  if (data.length === 0) {
    return (
      <div className={classes.skeleton}>
        <Skeleton height="100%" />
        <p className={classes.skeletonContent}>
          {isAdmin ? 'No data available' : 'Data collection...'}
        </p>
      </div>
    );
  }

  const adminTableCellStyles = isAdmin
    ? {
        display: 'flex',
        width: '360px',
        gap: 'var(--utilities-size-1-000)',
        paddingTop: 'var(--utilities-size-1-000)',
        paddingBottom: 'var(--utilities-size-1-250)',
        marginLeft: '-24px',
      }
    : {};

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderArrowCell} />
                  <TableCell
                    style={{
                      textAlign: 'start',
                      ...adminTableCellStyles,
                    }}
                    className={classes.tableHeaderCell}
                  >
                    <span>Property</span>
                    {isAdmin && (
                      <>
                        <input
                          name="text"
                          className={classes.searchInput}
                          value={searchInput}
                          onChange={e => {
                            setSearchInput(e.target.value);
                            setPage(0);
                          }}
                        />
                        {searchInput.length > 0 && (
                          <Close
                            className={classes.searchClean}
                            onClick={handleClearSearch}
                          />
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Customer Email
                  </TableCell>
                  <TableCell
                    className={classes.tableHeaderCell}
                    onMouseEnter={() => setArrowIconColor('5646E5')}
                    onMouseLeave={() => setArrowIconColor(null)}
                  >
                    Creation Date
                    <LeadIcon
                      onClick={handleSortOrderChange}
                      className={clsx(
                        classes.sortIcon,
                        isSortedAsc && classes.sortIconAsc,
                      )}
                      color={arrowIconColor || '6B7280'}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Start Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Next Billing Date
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    Invoice Price
                  </TableCell>
                  <TableCell
                    style={{ paddingLeft: '44px' }}
                    className={classes.tableHeaderCell}
                    onMouseEnter={() => setHoveredStatus(true)}
                    onMouseLeave={() => setHoveredStatus(null)}
                  >
                    <Box className={classes.statusCell}>
                      <Box
                        className={classes.sortImgContainer}
                        onClick={handleSortCircleClick}
                      >
                        <Box>Status</Box>
                        <SortCircle
                          className={classes.statusImg}
                          color={hoveredStatus ? '#5646E5' : '#6B7280'}
                        />
                      </Box>
                      <Tooltip
                        classes={tooltipClass}
                        placement="top"
                        title={
                          'Click on status button for subscription managing'
                        }
                      >
                        <Box
                          className={classes.statusImgContainer}
                          onMouseEnter={() => setHoveredHelpIcon(true)}
                          onMouseLeave={() => setHoveredHelpIcon(null)}
                        >
                          <HelpCircle
                            className={classes.statusImg}
                            color={hoveredHelpIcon ? '#5646E5' : '#6B7280'}
                          />
                        </Box>
                      </Tooltip>
                      {isAdmin && showFormControl && (
                        <div className={classes.selectContainer}>
                          <select
                            className={classes.customSelect}
                            value={selectedStatus}
                            onChange={handleStatusChange}
                          >
                            <option className={classes.option} value="">
                              all
                            </option>
                            {statusOrder.map(status => (
                              <option
                                key={status}
                                value={status}
                                className={classes.option}
                              >
                                {status}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData(data).map((property, index) => (
                  <React.Fragment key={property.property_id}>
                    <TableRow
                      onClick={event =>
                        toggleOpenItem(event, property.property_id)
                      }
                      className={`${
                        property.locations[0].location_status === 'DISABLED' &&
                        classes.disabledTour
                      } ${classes.row}`}
                      onMouseEnter={() => setHoveredRow(property.property_id)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <TableCell
                        className={classes.tableRowNumberCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : '#2C313A',
                        }}
                      >
                        <Box className={classes.tourNumber}>
                          {`${page * rowsPerPage + index + 1}`}
                        </Box>
                      </TableCell>

                      <TableCell className={classes.tablePropertyCell}>
                        <Box className={classes.tablePropertyContentContainer}>
                          <Box className={classes.tablePropertyContent}>
                            <LeadIcon2
                              className={clsx(
                                classes.leadIcon2,
                                openedItemId === property.property_id &&
                                  classes.leadIcon2Rotate,
                              )}
                              color={
                                property.locations[0].location_status ===
                                'DISABLED'
                                  ? 'EF4444'
                                  : hoveredRow === property.property_id
                                  ? '5646E5'
                                  : '2C313A'
                              }
                            />
                            <Box
                              className={classes.multilocationName}
                              style={{
                                color:
                                  property.locations[0].location_status ===
                                  'DISABLED'
                                    ? '#EF4444'
                                    : hoveredRow === property.property_id
                                    ? '#5646E5'
                                    : '#2C313A',
                              }}
                            >
                              {decodeURIComponent(
                                capitalizeFirstLetters(property.property_name),
                              )}
                            </Box>
                          </Box>
                          <a
                            href={`${process.env.PLAYER_URL}?locationId=${property.locations[0].location_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleStartTour}
                          >
                            <Box
                              className={`${
                                property.is_photography_tour
                                  ? classes.startTourButtonGr
                                  : classes.startTourButtonBl
                              }`}
                            >
                              <PlayCircle
                                color={
                                  property.is_photography_tour
                                    ? '13AE70'
                                    : '5E59F3'
                                }
                              />
                            </Box>
                          </a>
                        </Box>
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : hoveredRow === property.property_id
                              ? '#5646E5'
                              : '#2C313A',
                        }}
                      >
                        {(property.qb_recurring_transaction_id ||
                          property.stripe_subscription_id) &&
                        property.hosting_customer_email
                          ? property.hosting_customer_email
                          : ''}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : hoveredRow === property.property_id
                              ? '#5646E5'
                              : '#2C313A',
                        }}
                      >
                        {property.creation_date
                          ? dateConverter(property.creation_date)
                          : ''}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : hoveredRow === property.property_id
                              ? '#5646E5'
                              : '#2C313A',
                        }}
                      >
                        {(property.qb_recurring_transaction_id ||
                          property.stripe_subscription_id) &&
                        property.hosting_start_date
                          ? dateConverter(property.hosting_start_date)
                          : ''}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : hoveredRow === property.property_id
                              ? '#5646E5'
                              : '#2C313A',
                        }}
                      >
                        {(property.qb_recurring_transaction_id ||
                          property.stripe_subscription_id) &&
                        property.hosting_next_billing_date
                          ? dateConverter(property.hosting_next_billing_date)
                          : ''}
                        {(property.qb_recurring_transaction_id ||
                          property.stripe_subscription_id) && (
                          <img
                            className={classes.logoImg}
                            src={`/static/icons/seenic/${
                              property.qb_recurring_transaction_id
                                ? 'quickbooks'
                                : 'stripe'
                            }.svg`}
                            alt={'Logo'}
                          />
                        )}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : hoveredRow === property.property_id
                              ? '#5646E5'
                              : '#2C313A',
                        }}
                      >
                        {(property.qb_recurring_transaction_id ||
                          property.stripe_subscription_id) &&
                        property.hosting_invoice_price
                          ? `$${property.hosting_invoice_price}`
                          : ''}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{
                          color:
                            property.locations[0].location_status === 'DISABLED'
                              ? '#EF4444'
                              : hoveredRow === property.property_id
                              ? '#5646E5'
                              : '#2C313A',
                        }}
                      >
                        {property.qb_recurring_transaction_id ? (
                          <QuickBooksStatusChip
                            isAdmin={isAdmin}
                            qbRecurringTransactionId={
                              property.qb_recurring_transaction_id
                            }
                            invoiceStatus={
                              property.qb_recurring_transaction_id &&
                              property.hosting_status
                            }
                            onClick={e => e.stopPropagation()}
                          />
                        ) : (
                          !property.qb_recurring_transaction_id && (
                            <StripeDialog
                              subsStatus={
                                property.stripe_subscription_id &&
                                property.hosting_status
                              }
                              subsId={property.stripe_subscription_id}
                              customerId={property.hosting_customer_id}
                              onStateChange={handleStateChangeInPaymentDialog}
                              isActive={property.hosting_status === 'active'}
                              isPaused={property.hosting_status === 'paused'}
                              isCanceled={
                                property.hosting_status === 'canceled'
                              }
                              onClick={e => e.stopPropagation()}
                            />
                          )
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ padding: 0 }} colSpan={9}>
                        <Collapse
                          in={openedItemId === property.property_id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box>
                            <Table size="small" aria-label="purchases">
                              <TableHead className={classes.locationsTableHead}>
                                <TableRow>
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {property.locations.length > 0 &&
                                  property.locations.map((location, index) => (
                                    <TableRow
                                      key={location.location_id}
                                      className={classes.tableRow}
                                    >
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        } ${classes.tableSubRowLocation}`}
                                        style={{
                                          color:
                                            location.location_status ===
                                            'DISABLED'
                                              ? '#EF4444'
                                              : '#2C313A',
                                        }}
                                      >
                                        {isAdmin
                                          ? convertUnicodeString(
                                              location.location_name,
                                            )
                                          : decodeURIComponent(
                                              location.location_name,
                                            )}
                                      </TableCell>
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                      <TableCell
                                        className={`${classes.tableCell} ${
                                          index ===
                                          property.locations.length - 1
                                            ? classes.lastCell
                                            : ''
                                        }`}
                                      />
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Card>

      {filteredData.length > 0 ? (
        <Box className={classes.footer}>
          <IconButton
            className={classes.footerPart1}
            onMouseEnter={() => setHoveredArrowLeft(true)}
            onMouseLeave={() => setHoveredArrowLeft(null)}
            onClick={() => handleChangePage(null, page - 1)}
            disabled={page === 0}
          >
            <ArrowLeft
              color={
                page === 0
                  ? '#2C313A33'
                  : hoveredArrowLeft
                  ? '#5E59F3'
                  : '#2C313A'
              }
            />
          </IconButton>
          <Box className={classes.footerPart2}>
            <Box className={classes.pagination}>
              Page {page + 1} of {Math.ceil(filteredData.length / rowsPerPage)}
            </Box>
          </Box>
          <IconButton
            className={classes.footerPart3}
            onMouseEnter={() => setHoveredArrowRight(true)}
            onMouseLeave={() => setHoveredArrowRight(null)}
            onClick={() => handleChangePage(null, page + 1)}
            disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1}
          >
            <ArrowRight
              color={
                page >= Math.ceil(filteredData.length / rowsPerPage) - 1
                  ? '#2C313A33'
                  : hoveredArrowRight
                  ? '#5E59F3'
                  : '#2C313A'
              }
            />
          </IconButton>
        </Box>
      ) : (
        <Box className={classes.footerNoRec}>
          <Box className={classes.pagination}>No records</Box>
        </Box>
      )}
    </>
  );
};

HostingTable.propTypes = {
  className: PropTypes.string,
};

HostingTable.defaultProps = {
  className: '',
};

export default HostingTable;
