/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Box, makeStyles } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';

import debounce from '~/utils/debounce';
import { authRoles } from '~/constants';

import { searchLocations } from '~/store/gallery';
import SortBarCompany from '~/components/layouts/DashboardLayout/components/SortBar/SortBarCompany';
import { Close } from '~/views/private/billings/components/icons/Close';
import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import { Cube } from '~/views/private/overview/Components/icons/Cube';
import { convertUnicodeString } from '~/views/private/overview/utils';

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '327px',
    height: '100%',
    overflowY: 'auto',
    padding: 'var(--utilities-size-1-500) var(--utilities-size-0-500)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'flex',
    flexShrink: '0',
    borderRight: '1px solid var(--border-default)',
    background: 'var(--surface-pale)',
  },
  foldButton: {
    display: 'flex',
    padding:
      'var(--utilities-size-1-500) var(--utilities-size-0-250) var(--utilities-size-1-500) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-none)',
    cursor: 'pointer',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default) var(--utilities-border-radius-none)',
    borderTop: '1px solid var(--border-default)',
    borderRight: '1px solid var(--border-default)',
    borderBottom: '1px solid var(--border-default)',
    background: 'var(--surface-pal)',
    boxShadow: 'var(--shadows-subtle)',
    position: 'absolute',
    right: '-21px',
    top: '50%',
    zIndex: 10,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  searchBox: {
    display: 'flex',
    padding: 'var(--utilities-size-0-750) var(--utilities-size-2-500)',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    width: '100%',
    height: '44px',
    background: 'var(--surface-pale)',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-control-default)',
    boxShadow: 'var(--shadows-subtle)',
    boxSizing: 'border-box',
    backgroundImage: 'url(/static/icons/seenic/search.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'var(--utilities-size-1-000) 14px',
    backgroundSize: 'var(--utilities-size-1-000)',
    marginBottom: 'var(--utilities-size-0-500)',
    '&:hover': {
      border: '1px solid var(--border-hover-control-default-hover)',
      backgroundImage: 'url(/static/icons/seenic/search.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'var(--utilities-size-1-000) 14px',
      backgroundSize: 'var(--utilities-size-1-000)',
    },
    '&:focus, &:focus-visible': {
      backgroundImage: 'url(/static/icons/seenic/search.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'var(--utilities-size-1-000) 14px',
      backgroundSize: 'var(--utilities-size-1-000)',
      border: '1px solid var(--border-focus)',
      background: 'var(--surface-default)',
      outline: 'unset',
    },
    '&:-internal-autofill-selected': {
      backgroundImage: 'url(/static/icons/seenic/search.svg)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'var(--utilities-size-1-000) 14px',
      backgroundSize: 'var(--utilities-size-1-000)',
    },
  },
  searchClean: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    position: 'absolute',
    top: '38px',
    right: '30px',
    cursor: 'pointer',
  },
  emptySearch: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000) var(--utilities-size-none)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    color: 'var(--content-secondary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  treeItem: {
    '& .MuiTreeItem-group': {
      marginLeft: '0',
    },
    '& .MuiTreeItem-label': { padding: '0 !important' },
    '& .MuiTreeItem-iconContainer': { display: 'none !important' },
    '& .MuiTreeItem-label:hover': {
      borderRadius: 'var(--utilities-border-radius-default)',
      background: 'var(--surface-pale) !important',
    },
  },
  treeItemLocation: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    display: 'block',
    width: '100%',
    padding: 'var(--utilities-size-0-500) 4px var(--utilities-size-0-500) 46px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.active': {
      background: 'var(--surface-pale)',
      borderRadius: 'var(--utilities-border-radius-default)',
    },
  },
  treeItemLocationActiveSearch: {
    color: 'var(--content-success) !important',
  },
  boxOpen: {
    transition: '1s',
    position: 'relative',
  },
  boxClose: {
    position: 'relative',
    transition: '1s',
    marginLeft: '-327px',
  },
  leadIcon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    transform: 'rotate(90deg)',
  },
  leadIcon2: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    transform: 'rotate(180deg)',
  },
  leadIcon2Rotated: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    transform: 'none',
  },
  leadIconRotated: {
    transform: 'rotate(270deg)',
  },
  treeView: {
    padding: 'var(--utilities-size-0-500)',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'var(--surface-default)',
    borderBottom: '1px solid var(--border-default)',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--content-accent)',
    },
  },
  firstTreeView: {
    padding: 'var(--utilities-size-0-500)',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'var(--surface-default)',
    borderBottom: '1px solid var(--border-default)',
    borderTopLeftRadius: 'var(--utilities-border-radius-default)',
    borderTopRightRadius: 'var(--utilities-border-radius-default)',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--content-accent)',
    },
    '& > .Mui-expanded > $leadIcon': {
      transform: 'rotate(90deg) !important',
    },
  },
  lastTreeView: {
    padding: 'var(--utilities-size-0-500)',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'var(--surface-default)',
    borderBottomLeftRadius: 'var(--utilities-border-radius-default)',
    borderBottomRightRadius: 'var(--utilities-border-radius-default)',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--content-accent)',
    },
  },
  singleTreeView: {
    padding: 'var(--utilities-size-0-500)',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--content-accent)',
    },
  },
  footerBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: 'var(--utilities-size-1-000) 84px',
    flexShrink: '0',
    marginTop: 'auto',
  },
  poweredLogo: {
    width: '100px',
    height: 'auto',
  },
  leadIconClose: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    marginRight: 'var(--utilities-size-0-500)',
    transition: 'transform 0.3s',
  },
  leadIconOpen: {
    transform: 'rotate(90deg)',
  },
  multilocationName: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    display: 'block',
    width: '222px',
  },
  multilocationItem: {
    display: 'flex',
    alignItems: 'center',
    height: 'var(--utilities-size-2-500)',
    paddingLeft: '22px',
    '&:hover, &.active': {
      background: 'var(--surface-hover-default-hover)',
      color: 'var(--content-accent)',
      borderRadius: 'var(--utilities-border-radius-default)',
    },
  },
  locationItem: {
    display: 'flex',
    alignItems: 'center',
    height: 'var(--utilities-size-2-500)',
    '&:hover, &.active': {
      background: 'var(--surface-hover-default-hover)',
      color: 'var(--content-accent)',
      borderRadius: 'var(--utilities-border-radius-default)',
    },
  },
  buttonIcon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
  },
  explorerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-250)',
  },
}));

function searchClient(text, properties, options = {}) {
  const locationsSet = options.locationsSet || new Set();
  const explorers3DSet = options.explorers3DSet || new Set();
  const propertiesSet = options.propertiesSet || new Set();

  let propertiesCount = 0;

  for (const { locations, explorers3D, property_name } of properties) {
    if (property_name.toLowerCase().includes(text)) {
      propertiesSet.add(property_name);
      propertiesCount++;
    }

    for (const l of locations) {
      if (l.location_name.toLowerCase().includes(text)) {
        locationsSet.add(l.location_name);
        propertiesSet.add(property_name);
        propertiesCount++;
      }
    }

    for (const e of explorers3D) {
      if (e.name.toLowerCase().includes(text)) {
        explorers3DSet.add(e.name);
        propertiesSet.add(property_name);
        propertiesCount++;
      }
    }
  }

  return {
    locations: locationsSet,
    explorers3D: explorers3DSet,
    properties: propertiesSet,
    propertiesCount,
  };
}

function searchAdmin(text, companies) {
  const companiesSet = new Set();
  const propertiesSet = new Set();
  const locationsSet = new Set();
  const explorers3DSet = new Set();

  for (const { companyName, properties } of companies) {
    const searchResult = searchClient(text, properties, {
      locationsSet,
      explorers3DSet,
      propertiesSet,
    });

    if (companyName.toLowerCase().includes(text)) {
      if (
        properties.some(
          ({ locations, explorers3D }) =>
            locations.length > 0 || explorers3D.length > 0,
        )
      ) {
        companiesSet.add(companyName);
        for (const { property_name } of properties) {
          propertiesSet.add(property_name);
        }
      }
    } else if (searchResult.propertiesCount > 0) {
      if (
        properties.some(
          ({ locations, explorers3D }) =>
            locations.length > 0 || explorers3D.length > 0,
        )
      ) {
        companiesSet.add(companyName);
      }
    }
  }

  return {
    companies: companiesSet,
    properties: propertiesSet,
    locations: locationsSet,
    explorers3D: explorers3DSet,
  };
}

const SortBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [normalSearchText, setNormalSearchText] = useState('');
  const searchText = useSelector(state => state.gallery.search);
  const stats = useSelector(state => state.companyStats.data);
  const user = useSelector(state => state.auth.user);

  const [panelVisible, setPanelVisible] = useState(true);
  const [hoveredFoldButton, setHoveredFoldButton] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [hoveredLocationItemId, setHoveredLocationItemId] = useState(null);
  const [hoveredPropertyItemId, setHoveredPropertyItemId] = useState(null);

  const isAdmin =
    user.role === authRoles.ADMIN || user.role === authRoles.SUPER_ADMIN;
  const onToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const groupDataByCompany = dataArray => {
    const groupedData = dataArray.reduce((acc, item) => {
      const companyIndex = acc.findIndex(
        entry => entry.company_id === item.company_id,
      );

      const property = {
        property_id: item.property_id,
        property_name: item.property_name,
        locations: item.locations,
        explorers3D: item.explorers3D,
      };

      if (companyIndex !== -1) {
        acc[companyIndex].properties.push(property);
      } else {
        acc.push({
          companyName: item.companyName,
          company_id: item.company_id,
          properties: [property],
        });
      }

      return acc;
    }, []);

    return groupedData;
  };

  const isExpanded = node => expandedNodes.includes(node);

  let nodeId = 0;

  const updateSearchText = debounce(value => {
    dispatch(searchLocations(value.toLowerCase().trim()));
  }, 100);

  const handleChangeSearch = ({ target: { value } }) => {
    updateSearchText(value);
    setNormalSearchText(value);
  };

  const handleClearSearch = () => {
    dispatch(searchLocations(''));
    setNormalSearchText('');
  };

  const groupedCompanies = groupDataByCompany(stats);

  const searchResult = isAdmin
    ? searchAdmin(searchText, groupedCompanies)
    : searchClient(searchText, stats);

  return (
    <Box className={panelVisible ? classes.boxOpen : classes.boxClose}>
      <Box
        className={classes.foldButton}
        onClick={() => setPanelVisible(!panelVisible)}
        onMouseEnter={() => setHoveredFoldButton(true)}
        onMouseLeave={() => setHoveredFoldButton(null)}
      >
        <LeadIcon2
          className={
            panelVisible ? classes.leadIcon2 : classes.leadIcon2Rotated
          }
          color={hoveredFoldButton ? '5E59F3' : '2C313A'}
        />
      </Box>

      <Box className={classes.mainContainer}>
        <>
          <input
            name="text"
            placeholder="Search"
            className={classes.searchBox}
            onChange={handleChangeSearch}
            value={normalSearchText}
          />
          {normalSearchText.length > 0 && (
            <Close
              className={classes.searchClean}
              onClick={handleClearSearch}
            />
          )}
        </>
        {isAdmin &&
          groupedCompanies
            .filter(({ companyName }) =>
              searchResult.companies.has(companyName),
            )
            .map(({ companyName, properties }, index, array) => {
              const isFirstElement = index === 0;
              const isLastElement = index === array.length - 1;
              const isSingleElement = array.length === 1;
              let treeViewClass;

              if (isSingleElement) {
                treeViewClass = classes.singleTreeView;
              } else if (isFirstElement) {
                treeViewClass = classes.firstTreeView;
              } else if (isLastElement) {
                treeViewClass = classes.lastTreeView;
              } else {
                treeViewClass = classes.treeView;
              }

              return (
                <React.Fragment key={companyName + index.toString()}>
                  <TreeView className={treeViewClass}>
                    <SortBarCompany
                      companyName={companyName}
                      classes={classes}
                      properties={properties}
                      searchResult={searchResult}
                      index={index}
                      searchText={searchText}
                    />
                  </TreeView>
                </React.Fragment>
              );
            })}
        {isAdmin && searchResult.companies.size === 0 && (
          <Box className={classes.emptySearch}>Nothing found</Box>
        )}
        {!isAdmin &&
          stats
            .filter(({ property_name }) =>
              searchResult.properties.has(property_name),
            )
            .map(
              (
                {
                  property_name,
                  companyName,
                  locations,
                  explorers3D,
                  property_id,
                },
                index,
                array,
              ) => {
                const propertyNodeId = `${property_id}.${index}`;
                const isFirstElement = index === 0;
                const isLastElement = index === array.length - 1;
                const isSingleElement = array.length === 1;
                let treeViewClass;

                if (isSingleElement) {
                  treeViewClass = classes.singleTreeView;
                } else if (isFirstElement) {
                  treeViewClass = classes.firstTreeView;
                } else if (isLastElement) {
                  treeViewClass = classes.lastTreeView;
                } else {
                  treeViewClass = classes.treeView;
                }
                return (
                  (locations.length > 0 ||
                    (locations.length === 0 &&
                      explorers3D &&
                      explorers3D.length > 0)) && (
                    <TreeView
                      expanded={expandedNodes}
                      onNodeToggle={onToggle}
                      className={treeViewClass}
                      key={property_name + property_id}
                    >
                      <TreeItem
                        nodeId={propertyNodeId}
                        className={classes.treeItem}
                        onMouseEnter={() =>
                          setHoveredPropertyItemId(propertyNodeId)
                        }
                        onMouseLeave={() => setHoveredPropertyItemId(null)}
                        label={
                          <Box className={classes.multilocationItem}>
                            <LeadIcon2
                              className={cn(classes.leadIconClose, {
                                [classes.leadIconOpen]:
                                  isExpanded(propertyNodeId),
                              })}
                              color={
                                hoveredPropertyItemId === propertyNodeId
                                  ? '5E59F3'
                                  : '2C313A'
                              }
                            />
                            <Box>
                              {property_name ? (
                                <NavLink
                                  to={`/overview/multi/${encodeURIComponent(
                                    user.company,
                                  )}/${property_name}`}
                                  className={`${classes.ellipsis} ${classes.multilocationName}`}
                                  style={{
                                    color:
                                      hoveredPropertyItemId === propertyNodeId
                                        ? 'var(--content-accent)'
                                        : 'var(--content-primary)',
                                  }}
                                >
                                  {decodeURIComponent(property_name)}
                                </NavLink>
                              ) : (
                                <NavLink
                                  to={`/overview/location/${property_id}`}
                                  className={`${classes.ellipsis} ${classes.multilocationName}`}
                                  style={{
                                    color:
                                      hoveredPropertyItemId === propertyNodeId
                                        ? 'var(--content-accent)'
                                        : 'var(--content-primary)',
                                  }}
                                >
                                  {decodeURIComponent(
                                    locations[0].location_name,
                                  )}
                                </NavLink>
                              )}
                            </Box>
                          </Box>
                        }
                      >
                        {locations.length > 0 &&
                          locations.map(location => {
                            return (
                              <TreeItem
                                key={`${location.location_name} ${location.location_id}`}
                                nodeId={String(++nodeId)}
                                onMouseEnter={() =>
                                  setHoveredLocationItemId(location.location_id)
                                }
                                onMouseLeave={() =>
                                  setHoveredLocationItemId(null)
                                }
                                label={
                                  <Box className={classes.locationItem}>
                                    <NavLink
                                      to={`/overview/location/${location.location_id}`}
                                      className={cn({
                                        [classes.treeItemLocation]: true,
                                        [classes.treeItemLocationActiveSearch]:
                                          !!searchText &&
                                          searchResult.locations.has(
                                            location.location_name,
                                          ),
                                      })}
                                      style={{
                                        color:
                                          hoveredLocationItemId ===
                                          location.location_id
                                            ? 'var(--content-accent)'
                                            : 'var(--content-primary)',
                                      }}
                                    >
                                      {decodeURIComponent(
                                        location.location_name,
                                      )}
                                    </NavLink>
                                  </Box>
                                }
                              />
                            );
                          })}
                        {explorers3D.length > 0 &&
                          explorers3D.map(explorer3D => {
                            return (
                              <TreeItem
                                key={`${explorer3D.name} ${explorer3D.locationId}`}
                                nodeId={String(++nodeId)}
                                onMouseEnter={() =>
                                  setHoveredLocationItemId(
                                    explorer3D.locationId,
                                  )
                                }
                                onMouseLeave={() =>
                                  setHoveredLocationItemId(null)
                                }
                                label={
                                  <Box className={classes.locationItem}>
                                    <NavLink
                                      to={`/overview/multi/3dExplorer/${encodeURIComponent(
                                        companyName,
                                      )}/${encodeURIComponent(
                                        property_name,
                                      )}/${encodeURIComponent(
                                        explorer3D.name,
                                      )}`}
                                      className={cn({
                                        [classes.treeItemLocation]: true,
                                        [classes.treeItemLocationActiveSearch]:
                                          !!searchText &&
                                          searchResult.explorers3D.has(
                                            explorer3D.name,
                                          ),
                                      })}
                                      style={{
                                        color:
                                          hoveredLocationItemId ===
                                          explorer3D.locationId
                                            ? 'var(--content-accent)'
                                            : 'var(--content-primary)',
                                      }}
                                    >
                                      <Box className={classes.explorerItem}>
                                        <Cube
                                          className={classes.buttonIcon}
                                          color={
                                            !!searchText &&
                                            searchResult.explorers3D.has(
                                              explorer3D.name,
                                            )
                                              ? 'var(--content-success)'
                                              : hoveredLocationItemId ===
                                                explorer3D.locationId
                                              ? 'var(--content-accent)'
                                              : 'var(--content-primary)'
                                          }
                                        />
                                        {convertUnicodeString(explorer3D.name)}
                                      </Box>
                                    </NavLink>
                                  </Box>
                                }
                              />
                            );
                          })}
                      </TreeItem>
                    </TreeView>
                  )
                );
              },
            )}
        {!isAdmin && searchResult.properties.size === 0 && (
          <Box className={classes.emptySearch}>Nothing found</Box>
        )}
        <Box className={classes.footerBox}>
          <img
            src={'/static/icons/seenic/logo-powered.svg'}
            alt="Logo powered"
            className={classes.poweredLogo}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SortBar;
