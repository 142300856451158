/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, NavLink } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Line } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';

import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { makeStyles, Tooltip, Box, TextField, Button } from '@material-ui/core';

import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';
import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getPropertyOverallStats,
  getPropertyViewsChartStats,
  getPropertyHeatmapStats,
  setGAnalyticsId,
  get3DExplorerOverallStats,
} from '~/services/api';
import { formatDateFromSeconds } from '~/utils/helper';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';

import { trySetRange } from '~/store/datePicker';
import LocationsGalleryView from '~/views/private/gallery/LocationsGalleryView';
import { ArrowLeft } from '~/views/private/billings/components/icons/ArrowLeft';
import { Panorama } from '~/views/private/overview/Components/icons/Panorama';
import { Cube } from '~/views/private/overview/Components/icons/Cube';
import { CardStat } from '~/views/private/overview/Components/CardStat';
import { Download } from '~/views/private/overview/Components/icons/Download';
import { Eye } from '~/views/private/overview/Components/icons/Eye';
import { Target } from '~/views/private/overview/Components/icons/Target';
import { Timer } from '~/views/private/overview/Components/icons/Timer';
import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import HeatMap from '~/views/private/overview/Components/HeatMap';
import ImageWithFallback from '~/components/shared/ImageWithFallback';
import { Formik } from 'formik';
import clsx from 'clsx';
import { TOOLTIP_TYPE } from './constants';
import {
  calculateChartSize,
  calculateEventsInIntervals,
  calculateImgSize,
  filterStatsByDate,
  formatLabels,
  convertUnicodeString,
} from './utils';
import { GraphTooltip, MultiDateRangePicker, TimeStep } from './Components';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: 'var(--utilities-size-1-500)',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    padding:
      'var(--utilities-size-none) var(--utilities-size-none) var(--utilities-size-0-750) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-default)',
  },
  headerTextSection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    flex: '1 0 0',
  },
  headerButtonsSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
  },
  backButton: {
    display: 'flex',
    width: '52px',
    height: '52px',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-tint-weak)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-tint-weak-hover)',
    },
  },
  backButtonIcon: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500, 8px)',
    alignSelf: 'stretch',
  },
  headerTitleText: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  headerSubTitleText: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  virtualTourButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-accent-weak)',
    background: 'var(--surface-accent-tint)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid var(--border-hover-accent-weak-hover)',
      background: 'var(--surface-hover-accent-tint-hover)',
    },
  },
  buttonIcon: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  virtualTourButtonText: {
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  explorerButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-accent)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-accent-hover)',
    },
  },
  explorerButtonText: {
    color: 'var(--content-inverted)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  infoCardContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    paddingTop: 'var(--utilities-size-1-500)',
  },
  cardStat: {
    flex: '1 !important',
    flexGrow: '1 !important',
    justifyContent: 'center !important',
    width: 'unset !important',
  },
  designComponentInstanceNode: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-lg-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  sectionHeader: {
    color: 'var(--content-primary)',
    fontSize: 'var(--typography-font-size-md)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  downloadIconContainer: {
    position: 'relative',
    boxShadow: '0px 1px 2px rgba(23, 28, 38, 0.05)',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 'var(--utilities-size-0-250) 6px',
    height: 'var(--utilities-size-1-500)',
    marginLeft: 'var(--utilities-size-1-000)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgba(70, 86, 229, 0.4)',
    },
  },
  downloadIcon: {
    position: 'relative',
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    overflow: 'hidden',
    flexShrink: 0,
  },
  chartHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 0 20px 0',
  },
  container: {
    height: '480px',
  },
  locationsHeader: {
    padding: 'var(--utilities-size-1-500) var(--utilities-size-none)',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  explorerContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    alignSelf: 'stretch',
    borderRadius:
      '8px 8px var(--utilities-border-radius-none) var(--utilities-border-radius-none)',
  },
  explorerHeader: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  explorerContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
  },
  explorerStatsItemContainer: {
    display: 'flex',
    padding: '0px var(--utilities-size-1-000)',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderLeft: '1px solid var(--border-default)',
  },
  explorerStatsText: {
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-0-250)',
    alignSelf: 'stretch',
  },
  statsHeader: {
    overflow: 'hidden',
    color: 'var(--content-secondary,)',
    textOverflow: 'ellipsis',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  statsValue: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-lg-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  statsIcon: {
    width: 'var(--utilities-size-2-000)',
    height: 'var(--utilities-size-2-000)',
  },
  explorerFooter: {
    display: 'flex',
    height: 'var(--utilities-size-3-500)',
    padding: 'var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-250)',
    alignSelf: 'stretch',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-none) 8px 8px',
    borderTop: '1px solid var(--border-default)',
    cursor: 'pointer',
    backgroundColor: 'var(--surface-default)',
    '&:hover': {
      background: 'var(--surface-hover-default-hover)',
      '& $textWrapper': {
        color: 'var(--content-hover-primary-hover)',
      },
    },
  },
  textWrapper: {
    color: 'var(--content-primary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  utmContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  utmTable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-none)',
    alignSelf: 'stretch',
  },
  utmTableRow: {
    display: 'flex',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-rude-weak)',
  },
  utmTableHeaderCell: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  heatMapContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  heatMapContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-7-500)',
    alignSelf: 'stretch',
  },
  heatMapTable: {
    // display: 'flex',
    width: '500px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-none)',
    alignSelf: 'stretch',
    maxHeight: '400px',
    overflowY: 'auto',
    paddingRight: 'var(--utilities-size-0-500)',
  },
  divider: {
    height: '1px',
    margin: 'var(--utilities-size-1-500) var(--utilities-size-none)',
    background: 'var(--border-default)',
  },
  googleAnalytics: {
    display: 'flex',
    height: '70px',
    padding: 'var(--utilities-size-1-000)',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  gaText: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  gaInputContainer: {
    flexGrow: 1,
    width: 'auto',
    margin: 0,
  },
  gaInput: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    boxSizing: 'border-box',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    alignItems: 'center',
    flex: '1 0 0',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-control-default)',
    background: 'var(--surface-default)',
    boxShadow: 'var(--shadows-subtle)',
    '&:focus-visible': {
      outline: 'none',
      border: '2px solid var(--border-focus)',
    },
    '& + fieldset': {
      border: 'none',
    },
  },
  gaInputError: {
    borderColor: 'red',
    '&:focus-visible': {
      outline: 'none',
      border: '2px solid var(--border-focus)',
      borderColor: 'red',
    },
  },
  updateGA: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-accent)',
    boxShadow: 'var(--shadows-subtle)',
    color: 'var(--content-inverted)',
    cursor: 'pointer',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    '&:hover': {
      background: 'var(--surface-hover-accent-hover)',
    },
  },
  leadIcon2: {
    height: 'var(--utilities-size-1-250)',
    position: 'relative',
    width: 'var(--utilities-size-1-250)',
  },
  emptyHeatMapTable: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000) var(--utilities-size-none)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    color: 'var(--content-secondary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  image: {
    width: '360px',
    height: 'var(--utilities-size-7-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    objectFit: 'cover',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '240px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const BASE_URL = process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT;

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(94, 89, 243, 0.6)');
gradient.addColorStop(1, 'rgba(94, 89, 243, 0)');

const MultilocationOverviewView = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;
  const companyStats = useSelector(state => state.companyStats.data);

  const refVisits = useRef();
  const refVisitsScroll = useRef();
  const initialVisitsDateValue = useRef(visitsDateValue);

  let { listId, companyName } = useParams();
  listId = convertUnicodeString(listId);
  companyName = decodeURIComponent(companyName);

  const [filteredVisitStats, setFilteredVisitStats] = useState([]);
  const [heatMapStats, setHeatMapStats] = useState(null);

  const [hoveredVTButton, setHoveredVTButton] = useState(null);
  const [downloadIconColor, setDownloadIconColor] = useState(null);
  const [arrowIconColor, setArrowIconColor] = useState(null);

  const propertyData = companyStats.find(
    property =>
      property.companyName === companyName &&
      decodeURIComponent(property.property_name) === listId,
  );

  function extractExplorerIds(data) {
    if (!Array.isArray(data.explorers3D)) {
      return [];
    }

    return data.explorers3D
      .map(explorer => explorer.locationId)
      .filter(Boolean);
  }

  const explorerIds = propertyData ? extractExplorerIds(propertyData) : [];

  const stats = useAsync(async () => {
    try {
      const propertyOverallStats = await getPropertyOverallStats(
        listId,
        companyName,
        explorerIds,
      );

      propertyOverallStats.explorers3D = propertyData
        ? propertyData.explorers3D
        : [];

      return propertyOverallStats;
    } catch (error) {
      console.error('property:stats', error);
    }
  }, [listId]);

  const propertyVisitsStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getPropertyViewsChartStats(
          listId,
          companyName,
          explorerIds,
        );
      }
    } catch (e) {
      console.error('property:visitStats', e);
      return [];
    }
  }, [stats.loading]);

  const propertyHeatMapStats = useAsync(async () => {
    try {
      if (!stats.loading && !propertyVisitsStats.loading) {
        return await getPropertyHeatmapStats(listId, companyName, explorerIds);
      }
    } catch (e) {
      console.error('property:heatMapStats', e);
      return [];
    }
  }, [stats.loading]);

  const handleSavePdf = useCallback(canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    const docDefinition = {
      content: [
        {
          text: 'Views Summary',
          style: 'header',
        },
        {
          text: '\nTotal number of views for the specified period',
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        calculateImgSize(imgData, canvas.width, canvas.height),
      ],
      styles: {
        header: {
          fontSize: 23,
          bold: true,
        },
      },
      pageOrientation: 'landscape',
    };

    pdfMake.createPdf(docDefinition).download();
  }, []);

  const changeVisitsTimestampHandler = useCallback(() => {
    refVisitsScroll.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (refVisitsScroll.current) {
      const { scrollWidth } = refVisitsScroll.current;
      const { clientWidth } = refVisitsScroll.current;

      refVisitsScroll.current.scrollLeft = scrollWidth - clientWidth;
    }
  }, [filteredVisitStats]);

  useEffect(() => {
    if (
      !propertyVisitsStats.loading &&
      propertyVisitsStats.value &&
      propertyVisitsStats.value[0]
    ) {
      dispatch(
        trySetRange(
          defaultTimeRange(moment(propertyVisitsStats.value[0].eventDate)),
        ),
      );
    }
  }, [dispatch, propertyVisitsStats]);

  useEffect(() => {
    if (
      !propertyVisitsStats.loading &&
      propertyVisitsStats.value &&
      visitsDateValue
    ) {
      setFilteredVisitStats(
        filterStatsByDate(propertyVisitsStats.value, visitsDateValue).slice(),
      );
    }
  }, [propertyVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (
      !stats.loading &&
      !propertyVisitsStats.loading &&
      propertyHeatMapStats.value
    ) {
      setHeatMapStats(propertyHeatMapStats.value);
    }
  }, [stats.loading, propertyVisitsStats.loading, propertyHeatMapStats.value]);

  useEffect(() => {
    if (propertyVisitsStats.value != null && visitsDateValue) {
      const dataVisits = calculateEventsInIntervals({
        data: propertyVisitsStats.value
          .slice()
          .map(({ eventDate, eventsCount }) => {
            return {
              eventDate,
              value: eventsCount,
            };
          }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredVisitStats(
        dataVisits.reverse().map(({ eventDate, value }) => ({
          eventDate,
          eventsCount: value,
        })),
      );
    }
  }, [currentStep, propertyVisitsStats, visitsDateValue]);

  useEffect(() => {
    initialVisitsDateValue.current = visitsDateValue;
  }, [visitsDateValue]);

  let locationVisits = {};
  let heatMapData = [];

  if (!stats.loading && !propertyVisitsStats.loading && heatMapStats) {
    heatMapData = heatMapStats;
  }

  if (!stats.loading && !propertyVisitsStats.loading && heatMapStats) {
    locationVisits = heatMapStats.reduce((acc, item) => {
      const subdivisionCode = item.principalSubdivisionCode.split('-')[1];
      const cityKey = `${item.city}, ${subdivisionCode}`;
      if (!acc[cityKey]) {
        acc[cityKey] = 0;
      }
      acc[cityKey] += item.visitsCount;
      return acc;
    }, {});
  }

  const sortedLocations = Object.entries(locationVisits).sort(
    (a, b) => b[1] - a[1],
  );

  const calculateSums = useCallback(data => {
    if (data.loading) return 0;

    const locationSums = data.value.locations.reduce(
      (acc, location) => {
        acc.conversion += Object.values(location.conversion || {}).reduce(
          (sum, val) => sum + (val || 0),
          0,
        );

        acc.custom_button_hotspot_clicks += Object.values(
          location.custom_button_hotspot_clicks || {},
        ).reduce((sum, val) => sum + (val || 0), 0);

        acc.duration += Object.values(location.duration || {}).reduce(
          (sum, val) => sum + (val || 0),
          0,
        );

        acc.last_month_views_count += Object.values(
          location.last_month_views_count || {},
        ).reduce((sum, val) => sum + (val || 0), 0);

        acc.views_count += Object.values(location.views_count || {}).reduce(
          (sum, val) => sum + (val || 0),
          0,
        );

        acc.mobile_views += location.views_count.location_views_mobile || 0;

        acc.total_units = data.value.total_units;

        acc.available_units = data.value.available_units;

        if (location.tags_visits && Array.isArray(location.tags_visits)) {
          location.tags_visits.forEach(tag => {
            const tagName = tag.tag_name;
            const visitsCount = tag.visits_count || 0;
            if (!acc.tags_visits_map[tagName]) {
              acc.tags_visits_map[tagName] = visitsCount;
            } else {
              acc.tags_visits_map[tagName] += visitsCount;
            }
          });
        }
        if (location.vendor_visits && Array.isArray(location.vendor_visits)) {
          location.vendor_visits.forEach(vendor => {
            const vendorPreset = vendor.vendor_name;
            const visitsCount = vendor.visits_count || 0;
            if (!acc.vendor_visits_map[vendorPreset]) {
              acc.vendor_visits_map[vendorPreset] = visitsCount;
            } else {
              acc.vendor_visits_map[vendorPreset] += visitsCount;
            }
          });
        }
        return acc;
      },
      {
        conversion: 0,
        custom_button_hotspot_clicks: 0,
        duration: 0,
        last_month_views_count: 0,
        views_count: 0,
        mobile_views: 0,
        total_units: 0,
        available_units: 0,
        tags_visits_map: {},
        vendor_visits_map: {},
      },
    );

    const explorerSums = data.value.explorers.reduce(
      (acc, explorer) => {
        acc.conversion += Object.values(explorer.conversion || {}).reduce(
          (sum, val) => sum + (val || 0),
          0,
        );

        acc.custom_button_hotspot_clicks += Object.values(
          explorer.custom_button_hotspot_clicks || {},
        ).reduce((sum, val) => sum + (val || 0), 0);

        acc.duration += Object.values(explorer.duration || {}).reduce(
          (sum, val) => sum + (val || 0),
          0,
        );

        acc.last_month_views_count += Object.values(
          explorer.last_month_views_count || {},
        ).reduce((sum, val) => sum + (val || 0), 0);

        acc.views_count += Object.values(explorer.views_count || {}).reduce(
          (sum, val) => sum + (val || 0),
          0,
        );

        acc.mobile_views +=
          explorer.views_count.location_3d_explorer_views_mobile || 0;

        return acc;
      },
      {
        conversion: 0,
        custom_button_hotspot_clicks: 0,
        duration: 0,
        last_month_views_count: 0,
        views_count: 0,
        mobile_views: 0,
      },
    );

    return {
      conversion: locationSums.conversion + explorerSums.conversion,
      custom_button_hotspot_clicks:
        locationSums.custom_button_hotspot_clicks +
        explorerSums.custom_button_hotspot_clicks,
      duration: locationSums.duration + explorerSums.duration,
      last_month_views_count:
        locationSums.last_month_views_count +
        explorerSums.last_month_views_count,
      views_count: locationSums.views_count + explorerSums.views_count,
      mobile_views: locationSums.mobile_views + explorerSums.mobile_views,
      total_units: locationSums.total_units,
      available_units: locationSums.available_units,
      tags_visits_map: locationSums.tags_visits_map,
      vendor_visits_map: locationSums.vendor_visits_map,
    };
  }, []);

  const calculateTotal = count => {
    return Object.values(count).reduce((sum, count) => sum + count, 0);
  };

  const locations = useMemo(() => {
    if (stats.loading) return [];
    if (stats.value.locations.length !== 0) {
      const locationStats = stats.value.locations
        .map(location => ({
          ...location,
          totalViews: calculateTotal(location.views_count),
          totalDuration: calculateTotal(location.duration),
          totalConversion: calculateTotal(location.conversion),
        }))
        .sort((a, b) => b.totalViews - a.totalViews);
      return locationStats;
    }
  }, [stats]);

  const explorers = useMemo(() => {
    if (stats.loading) return [];
    if (stats.value.explorers3D.length !== 0) {
      return stats.value.explorers3D.map(explorer => ({ ...explorer }));
    }
  }, [stats]);

  const explorerStats = useAsync(async () => {
    try {
      return (
        !stats.loading &&
        explorers &&
        explorers.length !== 0 &&
        (await get3DExplorerOverallStats(`${explorers[0].locationId}`))
      );
    } catch (error) {
      console.error('explorer3D:stats', error);
    }
  }, [stats, explorers]);

  const propertyOverallStats = calculateSums(stats);

  const defaultTags = {
    website: 0,
    sitemap: 0,
    qrcode: 0,
    directLink: 0,
    google_ads: 0,
    LinkedIn: 0,
  };

  const defaultVendors = {
    apts: { name: 'Apartments.com', visits: 0 },
    aptlist: { name: 'Apartmentlist.com', visits: 0 },
    rentcom: { name: 'Rent.com', visits: 0 },
  };

  const handleUpdateGAnalyticsId = useCallback(
    async (values, { setSubmitting, setErrors }) => {
      try {
        if (!stats.loading) {
          const multilocationId = stats.value.property_id;
          await setGAnalyticsId(multilocationId, values.GAnalyticsId);
        }
      } catch (error) {
        setErrors({ GAnalyticsId: 'Failed to update Google Analytics ID' });
        console.error('Error updating Google Analytics ID:', error);
      } finally {
        setSubmitting(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [stats.loading, stats?.value?.property_id],
  );

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <Box className={classes.headerContainer}>
          <Box className={classes.headerTextSection}>
            <NavLink to="/gallery">
              <Box className={classes.backButton}>
                <ArrowLeft className={classes.backButtonIcon} />
              </Box>
            </NavLink>
            <Box className={classes.headerTitleContainer}>
              <Box className={classes.headerTitle}>
                <Box className={classes.headerTitleText}>{listId}</Box>
              </Box>
              <Box className={classes.headerSubTitleText}>
                Virtual Tour locations summary
              </Box>
            </Box>
          </Box>
          <Box className={classes.headerButtonsSection}>
            <a
              href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${
                !stats.loading && locations[0].location_id
              }`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <Box
                className={classes.virtualTourButton}
                onMouseEnter={() => setHoveredVTButton(true)}
                onMouseLeave={() => setHoveredVTButton(null)}
              >
                <Panorama
                  className={classes.buttonIcon}
                  color={
                    hoveredVTButton
                      ? 'var(--content-hover-accent-hover)'
                      : 'var(--content-accent)'
                  }
                />
                <Box
                  className={classes.virtualTourButtonText}
                  style={{
                    color: hoveredVTButton
                      ? 'var(--content-hover-accent-hover)'
                      : 'var(--content-accent)',
                  }}
                >
                  Virtual Tour
                </Box>
              </Box>
            </a>
            {!stats.loading && explorers && explorers.length !== 0 && (
              <a
                href={`${process.env.REACT_APP_WIDGET_URL}/overview/building?explorer3dId=${explorers[0].locationId}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => e.stopPropagation()}
              >
                <Box className={classes.explorerButton}>
                  <Cube
                    className={classes.buttonIcon}
                    color={'var(--content-inverted)'}
                  />
                  <Box className={classes.explorerButtonText}>3D Explorer</Box>
                </Box>
              </a>
            )}
          </Box>
        </Box>
      </Header>
      <Box className={classes.infoCardContainer}>
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          icon="off"
          title="Total Views"
          loading={stats.loading}
          value={
            propertyOverallStats.views_count !== 0
              ? propertyOverallStats.views_count
              : -1
          }
        />
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          icon="off"
          title="Last Month Views"
          loading={stats.loading}
          value={
            propertyOverallStats.last_month_views_count !== 0
              ? propertyOverallStats.last_month_views_count
              : -1
          }
        />
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          icon="off"
          title="Total Duration"
          loading={stats.loading}
          value={
            propertyOverallStats.duration !== 0
              ? formatDateFromSeconds(propertyOverallStats.duration)
              : '00:00'
          }
          tooltipText="Total Duration of viewing of the Virtual Tour"
        />
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          icon="off"
          title="Conversion"
          loading={stats.loading}
          value={
            propertyOverallStats.conversion !== 0
              ? propertyOverallStats.conversion
              : -1
          }
          tooltipText="Amounts of clicks to the Lease Now button at the bottom right corner"
        />
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          icon="off"
          title="Custom Button Clicks"
          loading={stats.loading}
          value={
            propertyOverallStats.custom_button_hotspot_clicks !== 0
              ? propertyOverallStats.custom_button_hotspot_clicks
              : -1
          }
          tooltipText="Amounts of clicks on the all buttons inside informational hotspots"
        />
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          loading={stats.loading}
          icon="on"
          title="Mobile"
          complexTooltip
          tooltipText={`Total views: ${
            stats.value && propertyOverallStats.mobile_views
          }`}
          value={
            (propertyOverallStats.mobile_views /
              propertyOverallStats.views_count) *
            100
          }
        />
        <CardStat
          className={classes.cardStat}
          frameClassName={classes.designComponentInstanceNode}
          loading={stats.loading}
          icon="on"
          title="Desktop"
          complexTooltip
          tooltipText={`Total views: ${
            stats.value &&
            propertyOverallStats.views_count - propertyOverallStats.mobile_views
          }`}
          value={
            100 -
            (propertyOverallStats.mobile_views /
              propertyOverallStats.views_count) *
              100
          }
        />
      </Box>

      <Box
        className="w-full flex gap-2 mt-2 mb-2"
        style={{ borderRadius: '8px', minHeight: '273px' }}
      >
        <Box
          className="w-3/5 h-auto bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '8px',
            boxShadow: 'var(--shadows-subtle)',
          }}
        >
          <Box className={classes.chartHeaderContainer}>
            <Box className="flex">
              <Tooltip
                placement="top"
                title="Amount of views applied range of times"
                classes={tooltipClass}
              >
                <Box className={classes.sectionHeader}>Views Summary</Box>
              </Tooltip>
              <Box
                className={classes.downloadIconContainer}
                onMouseEnter={() => setDownloadIconColor('5E59F3')}
                onMouseLeave={() => setDownloadIconColor(null)}
                onClick={() =>
                  handleSavePdf(refVisits.current.chartInstance.canvas)
                }
              >
                <Download
                  className={classes.downloadIcon}
                  color={downloadIconColor || '2C313A'}
                />
              </Box>
              <Box className="flex">
                <TimeStep
                  visitsDateValue={visitsDateValue}
                  onClick={changeVisitsTimestampHandler}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
              }}
            />
            <MultiDateRangePicker />
          </Box>
          <Box
            className={`w-full relative flex flex-col ${classes.container}`}
            ref={refVisitsScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <Box
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  filteredVisitStats.length * calculateChartSize(currentStep),
                overflowY: 'overlay',
              }}
            >
              {filteredVisitStats.length !== 0 ? (
                <Line
                  ref={refVisits}
                  data={{
                    datasets: [
                      {
                        backgroundColor: gradient,
                        lineTension: 0.01,
                        borderColor: '#5E59F3',
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: 'transparent',
                        pointRadius: size(15),
                        pointHoverRadius: size(15),
                        borderWidth: 1,
                        parsing: false,
                        data: filteredVisitStats.map(item => item.eventsCount),
                        label: '',
                      },
                    ],
                    labels: formatLabels(filteredVisitStats, currentStep),
                  }}
                  options={{
                    normalized: true,
                    animation: false,
                    cornerRadius: size(20),
                    layout: {
                      padding: {
                        right: size(13),
                      },
                    },
                    legend: { display: false },
                    maintainAspectRatio: false,
                    responsive: true,
                    devicePixelRatio: window.devicePixelRatio * 2,

                    hover: {
                      onHover(e, [chart]) {
                        if (!chart) {
                          dispatch(
                            updateVisitsTooltipInfo({
                              show: false,
                              showDashes: false,
                              showLabel: false,
                              showCircle: false,
                            }),
                          );
                          return;
                        }

                        const value = filteredVisitStats[chart._index];

                        dispatch(
                          setVisitsTooltipInfo({
                            showCircle: true,
                            showDashes: true,
                            type: TOOLTIP_TYPE.SMALL,
                            show: true,
                            value,
                            label: chart._xScale.ticks[chart._index],
                            smallTooltipText: `${value.eventsCount}`,
                            dashesHeight: `${
                              refVisits.current.chartInstance.height - 20
                            }px`,
                            showLabel: true,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            tooltipTop: chart._view.y + 28,
                            circleLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              10,
                            circleTop: chart._view.y + 50,
                            dashesLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            dashesTop:
                              e.target.parentElement.parentElement.offsetTop,
                            labelLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            labelBottom: 15,
                          }),
                        );
                      },
                    },
                    tooltips: {
                      enabled: false,
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          ticks: {
                            fontSize: size(8),
                            reverse: true,
                          },
                          categoryPercentage: 0.97,
                          barPercentage: 0.97,
                          barThickness: 28,
                          scaleLabel: {
                            display: true,
                            labelString: 'Date',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          ticks: {
                            fontStyle: 'bold',
                            fontColor: '#000',
                            stepSize: 250,
                            beginAtZero: true,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: 'Views',
                          },
                        },
                      ],
                    },
                  }}
                />
              ) : (
                <Skeleton height="99%" />
              )}
            </Box>
          </Box>
          <GraphTooltip store="visitsTooltip" />
        </Box>
        <Box
          className={`w-2/5 h-auto bg-white relative ${classes.utmContainer}`}
        >
          <Box className={classes.sectionHeader}>UTM Tracking</Box>
          <Box className={classes.utmTable}>
            <Box className={classes.utmTableRow}>
              <Box
                style={{ flex: '1 0 0' }}
                className={classes.utmTableHeaderCell}
              >
                Tag name
              </Box>
              <Box
                style={{
                  width: 'var(--utilities-size-5-000)',
                  textAlign: 'right',
                }}
                className={classes.utmTableHeaderCell}
              >
                Total visits
              </Box>
            </Box>
            {propertyOverallStats.tags_visits_map &&
            Object.keys(propertyOverallStats.tags_visits_map).length > 0 ? (
              <>
                {Object.entries(propertyOverallStats.tags_visits_map)
                  .sort(([, aValue], [, bValue]) => bValue - aValue)
                  .slice(0, 6)
                  .concat(
                    Object.entries(defaultTags)
                      .filter(
                        ([key]) =>
                          !Object.keys(
                            propertyOverallStats.tags_visits_map,
                          ).includes(key),
                      )
                      .slice(
                        0,
                        Math.max(
                          0,
                          6 -
                            Object.keys(propertyOverallStats.tags_visits_map)
                              .length,
                        ),
                      ),
                  )
                  .map(([key, value]) => (
                    <Box
                      key={key}
                      style={{
                        borderBottom: '1px solid var(--border-default)',
                      }}
                      className={classes.utmTableRow}
                    >
                      <Box
                        style={{
                          flex: '1 0 0',
                          color: 'var(--content-primary)',
                        }}
                        className={classes.utmTableHeaderCell}
                      >
                        {key}
                      </Box>
                      <Box
                        style={{
                          width: 'var(--utilities-size-5-000)',
                          textAlign: 'right',
                          color: 'var(--content-primary)',
                        }}
                        className={classes.utmTableHeaderCell}
                      >
                        {value}
                      </Box>
                    </Box>
                  ))}
              </>
            ) : (
              <>
                {Object.entries(defaultTags).map(([key, value]) => (
                  <Box
                    key={key}
                    style={{ borderBottom: '1px solid var(--border-default)' }}
                    className={classes.utmTableRow}
                  >
                    <Box
                      style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                      className={classes.utmTableHeaderCell}
                    >
                      {key}
                    </Box>
                    <Box
                      style={{
                        width: 'var(--utilities-size-5-000)',
                        textAlign: 'right',
                        color: 'var(--content-primary)',
                      }}
                      className={classes.utmTableHeaderCell}
                    >
                      {value}
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
          <Box className={classes.sectionHeader}>Vendor Tracking</Box>
          <Box className={classes.utmTable}>
            <Box className={classes.utmTableRow}>
              <Box
                style={{ flex: '1 0 0' }}
                className={classes.utmTableHeaderCell}
              >
                Vendor
              </Box>
              <Box
                style={{
                  width: 'var(--utilities-size-5-000)',
                  textAlign: 'right',
                }}
                className={classes.utmTableHeaderCell}
              >
                Total visits
              </Box>
            </Box>
            {propertyOverallStats.vendor_visits_map &&
            Object.keys(propertyOverallStats.vendor_visits_map).length > 0 ? (
              <>
                {Object.entries(propertyOverallStats.vendor_visits_map)
                  .sort(([, aValue], [, bValue]) => bValue - aValue)
                  .slice(0, 6)
                  .concat(
                    Object.entries(defaultVendors)
                      .filter(
                        ([key]) =>
                          !Object.keys(
                            propertyOverallStats.vendor_visits_map,
                          ).includes(key),
                      )
                      .slice(
                        0,
                        Math.max(
                          0,
                          6 -
                            Object.keys(propertyOverallStats.vendor_visits_map)
                              .length,
                        ),
                      ),
                  )
                  .map(([key, value]) => {
                    const vendorName = defaultVendors[key]?.name || key;
                    const visitCount = value.visits ?? value;
                    return (
                      <Box
                        key={key}
                        style={{
                          borderBottom: '1px solid var(--border-default)',
                        }}
                        className={classes.utmTableRow}
                      >
                        <Box
                          style={{
                            flex: '1 0 0',
                            color: 'var(--content-primary)',
                          }}
                          className={classes.utmTableHeaderCell}
                        >
                          {vendorName}
                        </Box>
                        <Box
                          style={{
                            width: 'var(--utilities-size-5-000)',
                            textAlign: 'right',
                            color: 'var(--content-primary)',
                          }}
                          className={classes.utmTableHeaderCell}
                        >
                          {visitCount}
                        </Box>
                      </Box>
                    );
                  })}
              </>
            ) : (
              <>
                {Object.entries(defaultVendors).map(([key, vendor]) => (
                  <Box
                    key={key}
                    style={{ borderBottom: '1px solid var(--border-default)' }}
                    className={classes.utmTableRow}
                  >
                    <Box
                      style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                      className={classes.utmTableHeaderCell}
                    >
                      {vendor.name}
                    </Box>
                    <Box
                      style={{
                        width: 'var(--utilities-size-5-000)',
                        textAlign: 'right',
                        color: 'var(--content-primary)',
                      }}
                      className={classes.utmTableHeaderCell}
                    >
                      {vendor.visits}
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {!stats.loading &&
        explorers &&
        explorers.length !== 0 &&
        explorerStats.value &&
        !explorerStats.loading && (
          <Box className="w-full flex gap-2 mt-2 mb-2">
            <Box
              className="w-full h-auto bg-white relative"
              style={{
                borderRadius: '8px',
                boxShadow: 'var(--shadows-subtle)',
              }}
            >
              <Box className={classes.explorerContainer}>
                <Box className={classes.explorerHeader}>
                  3D Explorer Summary
                </Box>
                <Box className={classes.explorerContent}>
                  <ImageWithFallback
                    className={classes.image}
                    src={`${BASE_URL}/locations/${
                      !stats.loading && locations[0].location_id
                    }/preview.jpg`}
                    fallbackSrc={`/static/images/no-img-placeholder.png`}
                    alt="Preview image"
                    isLoad={!stats.loading}
                    setIsLoad={() => {}}
                  />
                  <Box className={classes.explorerStatsItemContainer}>
                    <Eye
                      className={classes.statsIcon}
                      color={'var(--content-tertiary)'}
                    />
                    <Box className={classes.explorerStatsText}>
                      <Box className={classes.statsHeader}>Total Views</Box>
                      {stats.loading ? (
                        <Skeleton height="30px" />
                      ) : (
                        <Box className={classes.statsValue}>
                          {
                            explorerStats.value.views_count
                              .location_3d_explorer_total_views
                          }
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.explorerStatsItemContainer}>
                    <Target
                      className={classes.statsIcon}
                      color={'var(--content-tertiary)'}
                    />
                    <Box className={classes.explorerStatsText}>
                      <Box className={classes.statsHeader}>
                        Submit Conversion
                      </Box>
                      {stats.loading ? (
                        <Skeleton height="30px" />
                      ) : (
                        <Box className={classes.statsValue}>
                          {explorerStats.value.conversion.submit}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.explorerStatsItemContainer}>
                    <Timer
                      className={classes.statsIcon}
                      color={'var(--content-tertiary)'}
                    />
                    <Box className={classes.explorerStatsText}>
                      <Box className={classes.statsHeader}>Total Duration</Box>
                      {stats.loading ? (
                        <Skeleton height="30px" />
                      ) : (
                        <Box className={classes.statsValue}>
                          {explorerStats.value.duration
                            .location_3d_explorer_views_duration !== 0
                            ? formatDateFromSeconds(
                                explorerStats.value.duration
                                  .location_3d_explorer_views_duration,
                              )
                            : '00:00'}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <NavLink
                to={`/overview/multi/3dExplorer/${encodeURIComponent(
                  companyName,
                )}/${encodeURIComponent(listId)}/${encodeURIComponent(
                  explorers[0].name,
                )}`}
              >
                <Box
                  className={classes.explorerFooter}
                  onMouseEnter={() => setArrowIconColor('5E59F3')}
                  onMouseLeave={() => setArrowIconColor(null)}
                >
                  <Box className={classes.textWrapper}>See full analytics</Box>
                  <LeadIcon2
                    className={classes.leadIcon2}
                    color={arrowIconColor || '2C313A'}
                  />
                </Box>
              </NavLink>
            </Box>
          </Box>
        )}

      <Box className="w-full flex gap-2 mt-2 mb-2">
        <Box
          className={`w-full h-auto bg-white relative ${classes.heatMapContainer}`}
        >
          <Box className={classes.sectionHeader}>User Locations</Box>
          <Box className={classes.heatMapContent}>
            <Box className={classes.heatMapTable}>
              <Box className={classes.utmTableRow}>
                <Box
                  style={{ flex: '1 0 0' }}
                  className={classes.utmTableHeaderCell}
                >
                  Region
                </Box>
                <Box
                  style={{
                    width: 'var(--utilities-size-5-000)',
                    textAlign: 'right',
                  }}
                  className={classes.utmTableHeaderCell}
                >
                  Total visits
                </Box>
              </Box>
              {sortedLocations.length > 0 ? (
                sortedLocations.map(([city, count]) => (
                  <Box
                    key={city}
                    style={{ borderBottom: '1px solid var(--border-default)' }}
                    className={classes.utmTableRow}
                  >
                    <Box
                      style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                      className={classes.utmTableHeaderCell}
                    >
                      {city}
                    </Box>
                    <Box
                      style={{
                        width: 'var(--utilities-size-5-000)',
                        textAlign: 'right',
                        color: 'var(--content-primary)',
                      }}
                      className={classes.utmTableHeaderCell}
                    >
                      {count}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className={classes.emptyHeatMapTable}>Nothing found</Box>
              )}
            </Box>
            {heatMapData && <HeatMap data={heatMapData} />}
          </Box>
        </Box>
      </Box>

      {stats.loading ? (
        <Skeleton height="30px" />
      ) : (
        <Box className="w-full flex">
          <Box className={`w-7/12 flex ${classes.locationsHeader}`}>
            {`${locations.length} Location${locations.length > 1 ? 's' : ''}`}
          </Box>
        </Box>
      )}
      <LocationsGalleryView locations={locations} />

      <Box className={`w-full flex gap-2 mt-2 mb-2 ${classes.divider}`} />

      {stats.loading ? (
        <Skeleton height="70px" />
      ) : (
        <Formik
          initialValues={{ GAnalyticsId: stats?.value?.g_analytics_id || '' }}
          validationSchema={yup.object().shape({
            GAnalyticsId: yup
              .string()
              .matches(
                /^G-[A-Z0-9]{10}$/,
                'Invalid Google Analytics ID. Format should be G-XXXXXXXXXX',
              )
              .nullable(),
          })}
          onSubmit={handleUpdateGAnalyticsId}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            isSubmitting,
          }) => (
            <form
              className={`w-full flex ${classes.googleAnalytics}`}
              onSubmit={handleSubmit}
            >
              <Box className={classes.gaText}>GA4 Tracking Code</Box>
              <TextField
                className={classes.gaInputContainer}
                error={!!errors.GAnalyticsId}
                helperText={null}
                placeholder="Paste your code here"
                name="GAnalyticsId"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.GAnalyticsId}
                variant="outlined"
                inputProps={{
                  className: clsx(classes.gaInput, {
                    [classes.gaInputError]: !!errors.GAnalyticsId,
                  }),
                }}
              />
              <Button
                className={classes.updateGA}
                disabled={isSubmitting || !!errors.GAnalyticsId}
                type="submit"
                variant="contained"
              >
                <Box className={classes.continueButtonText}>
                  {isSubmitting ? 'Updating...' : 'Update'}
                </Box>
              </Button>
            </form>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default MultilocationOverviewView;
