/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { makeStyles, Box } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';

import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import { Cube } from '~/views/private/overview/Components/icons/Cube';
import { convertUnicodeString } from '~/views/private/overview/utils';

let nodeId = 0;

const useStyles = makeStyles(() => ({
  leadIcon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    marginRight: 'var(--utilities-size-0-500)',
    transition: 'transform 0.3s',
  },
  companyName: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  companyItem: {
    display: 'flex',
    alignItems: 'center',
    height: 'var(--utilities-size-2-500)',
    '&:hover, &.active': {
      background: 'var(--surface-hover-default-hover)',
      color: 'var(--content-accent)',
      borderRadius: 'var(--utilities-border-radius-default)',
    },
  },
  buttonIcon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
  },
  explorerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-250)',
  },
  explorerName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const SortBarCompany = ({
  companyName,
  properties,
  classes,
  searchResult,
  index,
  searchText,
}) => {
  const ownClasses = useStyles();
  const [hoveredCompanyItemId, setHoveredCompanyItemId] = useState(null);
  const [hoveredPropertyItemId, setHoveredPropertyItemId] = useState(null);
  const [hoveredLocationItemId, setHoveredLocationItemId] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const isExpanded = node => expandedNodes.includes(node);

  return (
    <TreeView expanded={expandedNodes} onNodeToggle={handleToggle}>
      <TreeItem
        className={classes.treeItem}
        onMouseEnter={() => setHoveredCompanyItemId(index)}
        onMouseLeave={() => setHoveredCompanyItemId(null)}
        nodeId={index.toString()}
        label={
          <Box className={ownClasses.companyItem}>
            <LeadIcon2
              className={cn(ownClasses.leadIcon, {
                [classes.leadIconOpen]: isExpanded(index.toString()),
              })}
              color={hoveredCompanyItemId === index ? '5E59F3' : '2C313A'}
            />
            <Box
              className={ownClasses.companyName}
              style={{
                color:
                  hoveredCompanyItemId === index
                    ? 'var(--content-accent)'
                    : 'var(--content-primary)',
              }}
            >
              {companyName}
            </Box>
          </Box>
        }
      >
        {properties
          .filter(({ property_name }) =>
            searchResult.properties.has(property_name),
          )
          .map(({ property_name, locations, explorers3D }, _index) => {
            const propertyNodeId = `${index}.${_index}`;
            return (
              (locations.length > 0 ||
                (locations.length === 0 && explorers3D.length > 0)) && (
                <TreeItem
                  key={property_name + index.toString()}
                  nodeId={propertyNodeId}
                  onMouseEnter={() => setHoveredPropertyItemId(propertyNodeId)}
                  onMouseLeave={() => setHoveredPropertyItemId(null)}
                  label={
                    <Box className={classes.multilocationItem}>
                      <LeadIcon2
                        className={cn(ownClasses.leadIcon, {
                          [classes.leadIconOpen]: isExpanded(propertyNodeId),
                        })}
                        color={
                          hoveredPropertyItemId === propertyNodeId
                            ? '5E59F3'
                            : '2C313A'
                        }
                      />
                      <Box>
                        {property_name && (
                          <NavLink
                            to={`/overview/multi/${encodeURIComponent(
                              companyName,
                            )}/${encodeURIComponent(property_name)}`}
                            className={`${classes.ellipsis} ${classes.multilocationName}`}
                            style={{
                              color:
                                hoveredPropertyItemId === propertyNodeId
                                  ? 'var(--content-accent)'
                                  : 'var(--content-primary)',
                            }}
                          >
                            {convertUnicodeString(property_name)}
                          </NavLink>
                        )}
                      </Box>
                    </Box>
                  }
                >
                  {locations.length > 0 &&
                    locations.map(location => {
                      return (
                        <TreeItem
                          key={`${location.location_name} ${location.location_id}`}
                          nodeId={String(++nodeId)}
                          onMouseEnter={() =>
                            setHoveredLocationItemId(location.location_id)
                          }
                          onMouseLeave={() => setHoveredLocationItemId(null)}
                          label={
                            <Box className={classes.locationItem}>
                              <NavLink
                                to={`/overview/location/${location.location_id}`}
                                className={cn({
                                  [classes.treeItemLocation]: true,
                                  [classes.treeItemLocationActiveSearch]:
                                    !!searchText &&
                                    searchResult.locations.has(
                                      location.location_name,
                                    ),
                                })}
                                style={{
                                  color:
                                    hoveredLocationItemId ===
                                    location.location_id
                                      ? 'var(--content-accent)'
                                      : 'var(--content-primary)',
                                }}
                              >
                                {convertUnicodeString(location.location_name)}
                              </NavLink>
                            </Box>
                          }
                        />
                      );
                    })}
                  {explorers3D.length > 0 &&
                    explorers3D.map(explorer3D => {
                      return (
                        <TreeItem
                          key={`${explorer3D.name} ${explorer3D.locationId}`}
                          nodeId={String(++nodeId)}
                          onMouseEnter={() =>
                            setHoveredLocationItemId(explorer3D.locationId)
                          }
                          onMouseLeave={() => setHoveredLocationItemId(null)}
                          label={
                            <Box className={classes.locationItem}>
                              <NavLink
                                to={`/overview/multi/3dExplorer/${encodeURIComponent(
                                  companyName,
                                )}/${encodeURIComponent(
                                  property_name,
                                )}/${encodeURIComponent(explorer3D.name)}`}
                                className={cn({
                                  [classes.treeItemLocation]: true,
                                  [classes.treeItemLocationActiveSearch]:
                                    !!searchText &&
                                    searchResult.explorers3D.has(
                                      explorer3D.name,
                                    ),
                                })}
                                style={{
                                  color:
                                    hoveredLocationItemId ===
                                    explorer3D.locationId
                                      ? 'var(--content-accent)'
                                      : 'var(--content-primary)',
                                }}
                              >
                                <Box className={ownClasses.explorerItem}>
                                  <Cube
                                    className={ownClasses.buttonIcon}
                                    color={
                                      !!searchText &&
                                      searchResult.explorers3D.has(
                                        explorer3D.name,
                                      )
                                        ? 'var(--content-success)'
                                        : hoveredLocationItemId ===
                                          explorer3D.locationId
                                        ? 'var(--content-accent)'
                                        : 'var(--content-primary)'
                                    }
                                  />
                                  <Box className={ownClasses.explorerName}>
                                    {convertUnicodeString(explorer3D.name)}
                                  </Box>
                                </Box>
                              </NavLink>
                            </Box>
                          }
                        />
                      );
                    })}
                </TreeItem>
              )
            );
          })}
      </TreeItem>
    </TreeView>
  );
};

export default SortBarCompany;
