import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from '~/components/layouts/DashboardLayout';
import MainLayout from '~/components/layouts/MainLayout';
import AccountView from '~/views/private/account/AccountView';
import DashboardView from '~/views/private/reports/DashboardView';
import NotFoundView from '~/views/public/errors/NotFoundView';
import ProductListView from '~/views/private/product/ProductListView';
import SettingsView from '~/views/private/settings/SettingsView';
import ContactsView from '~/views/private/contacts/ContactsView';
import GalleryView from '~/views/private/gallery/GalleryView';
import BillingsView from '~/views/private/billings/BillingsView';
import RecentlyGalleryView from '~/views/private/gallery/RecentlyGalleryView';
import OverviewView from '~/views/private/overview/OverviewView';
import LocationOverviewView from '~/views/private/overview/LocationOverviewView';
import LocationListView from '~/views/private/location/LocationListView';
import LocationView from '~/views/private/location/LocationView';
import CompanyListView from '~/views/private/company/CompanyListView';
import CompanyView from '~/views/private/company/CompanyView';
import MultilocationOverviewView from '~/views/private/overview/MultilocationOverviewView';
import Explorer3DOverviewView from '~/views/private/overview/Explorer3DOverviewView';

const routes = () => [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <Navigate to="/locations" replace /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'account', element: <AccountView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'contacts', element: <ContactsView /> },
      { path: 'billings', element: <BillingsView /> },
    ],
  },
  {
    path: 'gallery',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <GalleryView /> },
      { path: 'recently', element: <RecentlyGalleryView /> },
    ],
  },
  {
    path: 'locations',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <LocationListView /> },
      { path: ':id', element: <LocationView /> },
    ],
  },
  {
    path: 'overview',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <OverviewView /> },
      { path: 'location/:locationId', element: <LocationOverviewView /> },
      {
        path: 'multi/:companyName/:listId',
        element: <MultilocationOverviewView />,
      },
      {
        path: 'multi/3dExplorer/:companyName/:listId/:explorer3DName',
        element: <Explorer3DOverviewView />,
      },
    ],
  },
  {
    path: 'companies',
    element: <DashboardLayout />,
    children: [
      { path: '', element: <CompanyListView /> },
      { path: ':id', element: <CompanyView /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
];

export default routes;
