/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { authRoles } from '~/constants';
import { logout } from '~/services/magic';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 'var(--utilities-size-3-500)',
    display: 'flex',
    paddingRight: 'var(--utilities-size-1-500)',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'var(--surface-rude)',
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  logoContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    width: '326px',
    height: '100%',
    alignItems: 'center',
    backgroundColor: 'var(--surface-accent)',
    '&:hover': {
      backgroundColor: 'var(--surface-hover-accent-hover)',
    },
  },
  logoImg: {
    width: '104px',
    height: 'var(--utilities-size-1-750)',
    marginLeft: 'var(--utilities-size-1-500)',
  },
  tabsContainer: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    background: 'var(--surface-rude)',
  },
  navItem: {
    display: 'flex',
    padding: '0 25px',
    height: '100%',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: 'var(--typography-font-size-md)',
    lineHeight: 'var(--utilities-size-1-500)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    alignItems: 'center',
    color: 'var(--content-inverted)',
    background: 'var(--surface-rude)',
    borderRight: '1px solid var(--border-default-inverted)',
    '&:hover': {
      background: 'var(--surface-hover-rude-hover)',
    },
    '&.active': {
      background: 'var(--surface-inverted)',
      border: 'none',
      '& ~ $navItem:first-of-type': {
        borderRight: 'none',
      },
    },
    '&.active + $navItem': {
      borderLeft: 'none',
    },
  },
  navItemIcon: {
    width: 'var(--utilities-size-1-500)',
    height: 'var(--utilities-size-1-500)',
    marginRight: '10px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50% 50%',

    '&.overview': { backgroundImage: 'url(/static/icons/seenic/chart.svg)' },
    '&.billing': { backgroundImage: 'url(/static/icons/seenic/receipt.svg)' },
    '&.gallery': { backgroundImage: 'url(/static/icons/seenic/build.svg)' },
    '&.contact': { backgroundImage: 'url(/static/icons/seenic/mail.svg)' },
  },
  loginContainer: {
    height: 'var(--utilities-size-3-500)',
    display: 'flex',
    background: 'var(--surface-rude)',
    padding: 'var(--utilities-size-none)',
    alignItems: 'center',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: '14px',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    lineHeight: 'var(--typography-line-height-xs-line)',
  },
  userRole: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontWeight: '700',
    color: 'var(--content-inverted)',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-xs-line)',
  },
  userEmail: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontWeight: '400',
    color: 'var(--content-inverted-weak)',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-xs-line)',
  },
  logoutButton: {
    minWidth: '110px',
    justifyContent: 'center',
    maxWidth: '140px',
    height: 'var(--utilities-size-2-000)',
    display: 'flex',
    alignItems: 'center',
    background: 'var(--surface-bright)',
    textTransform: 'none',
    marginRight: '10px',
    borderRadius: 'var(--utilities-border-radius-default)',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'var(--utilities-size-1-250)',
      fontFamily: 'var(--typography-font-family-default) !important',
      fontWeight: '500',
      fontSize: 'var(--typography-font-size-sm)',
      lineHeight: 'var(--typography-line-height-sm-line)',
      color: 'var(--content-inverted)',
    },
    '&:hover': {
      background: 'var(--surface-hover-bright-hover)',
    },
  },
  logoutImg: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    marginLeft: 'var(--utilities-size-0-375)',
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const routerLocation = useLocation();
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const stats = useSelector(state => state.companyStats.data);

  const role =
    user.role === authRoles.SUPER_ADMIN
      ? 'SuperAdmin'
      : user.role === authRoles.ADMIN
      ? 'Admin'
      : 'Client';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [onMobileClose, openMobile, routerLocation.pathname]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
    } catch (err) {
      console.error(err);
    } finally {
      window.location.href = window.location.origin;
      setLoading(false);
    }
  };

  if (!user) return null;

  return (
    <Box className={classes.container}>
      <Box className={classes.mainContainer}>
        <Box className={classes.logoContainer}>
          <img
            className={classes.logoImg}
            src={'/static/icons/seenic/seenic-logo-mono-light.svg'}
            alt="Company logo"
          />
        </Box>
        <Box className={classes.tabsContainer}>
          <NavLink to="/overview" className={clsx(classes.navItem, 'overview')}>
            <div className={clsx(classes.navItemIcon, 'overview')} />
            Overview
          </NavLink>
          <NavLink to="/billings" className={clsx(classes.navItem, 'billing')}>
            <div className={clsx(classes.navItemIcon, 'billing')} />
            Hosting Fee
          </NavLink>
          <NavLink
            to={stats ? '/gallery' : '#'}
            className={clsx(classes.navItem, 'gallery')}
            onClick={event => {
              if (!stats) {
                event.preventDefault();
              }
            }}
          >
            <div className={clsx(classes.navItemIcon, 'gallery')} />
            Properties
          </NavLink>
          <NavLink to="/contacts" className={clsx(classes.navItem, 'contact')}>
            <div className={clsx(classes.navItemIcon, 'contact')} />
            Contact Us
          </NavLink>
        </Box>
      </Box>
      <Box className={classes.loginContainer}>
        <Box className={classes.userContainer}>
          <Box className={classes.userRole}>{role}</Box>
          <Box className={classes.userEmail}>{user.email}</Box>
        </Box>
        <Button
          type="button"
          variant="contained"
          className={classes.logoutButton}
          disabled={loading}
          onClick={handleLogout}
        >
          {loading ? 'Logging out' : 'Log out'}
          <img
            className={classes.logoutImg}
            src={'/static/icons/seenic/logout.svg'}
            alt="logout"
          />
        </Button>
      </Box>
    </Box>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
