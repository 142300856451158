/* eslint-disable prettier/prettier */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { LocalizationProvider, DateRangePicker } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment from 'moment';

import { changeVisible } from '~/store/datePicker';
import { Calendar } from '~/views/private/overview/Components/icons/Calendar';

const useStyles = makeStyles(() => ({
  datePickerContainer: {
    position: 'relative',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-pale)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
  },
  dateInput: {
    position: 'relative',
    boxShadow: 'var(--shadows-subtle)',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    width: '110px',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 0 4px 18px',
    gap: 'var(--utilities-size-0-250)',
    height: 'var(--utilities-size-1-500)',
    textAlign: 'center',
    fontSize: 'var(--typography-font-size-xs)',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    '&:hover': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
      color: 'var(--content-hover-primary-hover)',
      backgroundColor: 'var(--surface-default)',
    },
    '&:focus-visible': {
      border: '1px solid var(--content-primary)',
      color: 'var(--content-primary)',
      outline: 'none',
    },
  },
  label: {
    fontSize: 'var(--typography-font-size-xs)',
    lineHeight: 'var(--typography-line-height-xs-line)',
    fontWeight: '500',
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    margin: 'var(--utilities-size-none) var(--utilities-size-0-500)',
  },
  calendarIcon: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    position: 'absolute',
    overflow: 'hidden',
    flexShrink: '0',
    left: '51px',
    zIndex: '1',
  },
  calendarIcon2: {
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    position: 'absolute',
    overflow: 'hidden',
    flexShrink: '0',
    left: '190px',
    zIndex: '1',
  },
  noBoxShadow: {
    boxShadow: 'none !important',
  },
  dateRangePicker: {
    position: 'relative',
    boxShadow:
      '0px 0px 0px 1px rgba(23, 28, 38, 0.05), 0px 4px 8px rgba(23, 28, 38, 0.05), 0px 8px 16px -2px rgba(23, 28, 38, 0.05), 0px 12px 24px -4px rgba(23, 28, 38, 0.05)',
    borderRadius: 'var(--utilities-border-radius-xl)',
    backgroundColor: 'var(--surface-default)',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    fontSize: 'var(--typography-font-size-md)',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    padding: 'var(--utilities-size-none) var(--utilities-size-3-500)',
    '& .MuiPickersDateRangeDay-rangeIntervalDayHighlight': {
      background: 'var(--surface-accent-pale)',
      fontFamily: 'var(--typography-font-family-default) !important',
      fontSize: 'var(--typography-font-size-sm)',
    },
    '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
      border: '2px solid rgba(70, 86, 229, 0.12)',
    },
    '& .MuiPickersDateRangeDay-rangeIntervalDayPreview:not(.MuiPickersDateRangeDay-rangeIntervalDayPreviewEnd)':
      {
        borderRightColor: 'transparent',
      },
    '& .MuiPickersDateRangeDay-rangeIntervalDayPreview:not(.MuiPickersDateRangeDay-rangeIntervalDayPreviewStart)':
      {
        borderLeftColor: 'transparent',
      },
    '& .MuiPickersDateRangeDay-root:first-child .MuiPickersDateRangeDay-rangeIntervalDayPreview':
      {
        border: '2px solid rgba(70, 86, 229, 0.12)',
        borderRightColor: 'transparent',
      },
    '& .MuiPickersDateRangeDay-root:last-child .MuiPickersDateRangeDay-rangeIntervalDayPreview':
      {
        border: '2px solid rgba(70, 86, 229, 0.12)',
        borderLeftColor: 'transparent',
      },
    '& .MuiPickersDateRangeDay-notSelectedDate': {
      fontFamily: 'var(--typography-font-family-default) !important',
      fontSize: 'var(--typography-font-size-sm)',
      '&:hover': {
        border: '2px solid var(--content-accent)',
        background: 'var(--surface-hover-default-hover)',
      },
    },
    '& .MuiPickersDay-today:not(.Mui-selected)': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
    },
    '& .MuiTypography-subtitle1': {
      width: '100%',
      position: 'relative',
      fontSize: 'var(--typography-font-size-md)',
      fontWeight: '700',
      lineHeight: 'var(--typography-line-height-md-line)',
      display: 'inline-block',
      fontFamily: 'var(--typography-font-family-default) !important',
      fontStyle: 'normal',
      color: 'var(--content-primary)',
      textAlign: 'center',
      letterSpacing: 'var(--typography-letter-spacing-default)',
    },
    '& .MuiPickersDateRangeDay-dayInsideRangeInterval': {
      color: 'var(--surface-hover-accent-hover)',
    },
    '& .MuiPickersDateRangeDay-dayInsideRangeInterval:hover': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      background: 'var(--content-accent)',
      color: 'var(--surface-default)',
    },
    '& .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child)':
      {
        borderRight: 'none',
      },
    '& .MuiPickersArrowSwitcher-iconButton:first-of-type': {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: 'var(--utilities-size-3-500)',
      borderRadius: 'unset',
      borderRight: '1px solid rgba(23, 28, 38, 0.1)',
      '& .MuiSvgIcon-root': {
        fill: 'var(--content-primary)',
      },
      '&:hover': {
        background: 'var(--surface-hover-default-hover)',
        '& .MuiSvgIcon-root': {
          fill: 'var(--content-accent)',
        },
      },
    },
    '& .MuiPickersArrowSwitcher-iconButton:last-of-type': {
      position: 'absolute',
      top: '0',
      right: '0',
      height: '100%',
      width: 'var(--utilities-size-3-500)',
      borderRadius: 'unset',
      borderLeft: '1px solid rgba(23, 28, 38, 0.1)',
      '& .MuiSvgIcon-root': {
        fill: 'var(--content-primary)',
      },
      '&:hover': {
        background: 'var(--surface-hover-default-hover)',
        '& .MuiSvgIcon-root': {
          fill: 'var(--content-accent)',
        },
      },
    },
    '& .MuiTouchRipple-root': {
      position: 'unset',
    },
    '& .MuiPickersCalendar-weekDayLabel': {
      position: 'relative',
      fontSize: 'var(--typography-font-size-2xs)',
      lineHeight: 'var(--typography-line-height-2xs-line)',
      fontWeight: '500',
      fontFamily: 'var(--typography-font-family-default) !important',
      letterSpacing: 'var(--typography-letter-spacing-default)',
      fontStyle: 'normal',
      color: 'var(--content-secondary)',
      textAlign: 'center',
    },
  },
}));

const CustomDateRangePicker = React.memo(({ value, onChange, onOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [visible, setVisible] = useState(false);
  const [calendarIconColor, setCalendarIconColor] = useState(null);
  const [calendarIcon2Color, setCalendarIcon2Color] = useState(null);

  const onClose = useCallback(() => {
    dispatch(changeVisible(false));
    setVisible(false);
  }, [dispatch]);

  const openHandler = useCallback(() => {
    if (onOpen) {
      onOpen();
    }
    dispatch(changeVisible(true));
    setVisible(true);
  }, [dispatch, onOpen]);

  const handleChange = useCallback(
    newValue => {
      if (!newValue[1]) return;
      onChange(newValue);
    },
    [onChange],
  );

  useEffect(() => {
    if (visible) {
      const timeoutId = setTimeout(() => {
        const popupPaper = document.querySelector('.MuiPickersPopper-paper');
        if (popupPaper) {
          popupPaper.style.boxShadow = 'none';
        }
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const timeoutId = setTimeout(() => {
        const daysHeaders = document.querySelectorAll(
          '.MuiPickersCalendar-daysHeader',
        );
        if (daysHeaders) {
          const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

          daysHeaders.forEach(daysHeader => {
            const dayLabels = daysHeader.querySelectorAll(
              '.MuiPickersCalendar-weekDayLabel',
            );

            dayLabels.forEach((label, index) => {
              label.textContent = dayNames[index];
            });
          });
        }
      }, 0);
      return () => clearTimeout(timeoutId);
    }
  }, [visible]);

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <DateRangePicker
        value={value}
        minDate={moment().subtract(1, 'year')}
        maxDate={moment()}
        onClose={onClose}
        className={classes.dateRangePicker}
        open={visible}
        onOpen={openHandler}
        onChange={handleChange}
        renderInput={(startProps, endProps) => (
          <div className={classes.datePickerContainer}>
            <span className={classes.label}>From</span>
            <Calendar
              className={classes.calendarIcon}
              color={calendarIconColor || '2C313A'}
            />
            <input
              className={classes.dateInput}
              ref={startProps.inputRef}
              {...startProps.inputProps}
              value={moment(startProps.inputProps.value).format('MMM DD, YYYY')}
              onMouseEnter={() => setCalendarIconColor('5E59F3')}
              onMouseLeave={() => setCalendarIconColor(null)}
              onFocusCapture={() => setCalendarIconColor(null)}
            />
            <span className={classes.label}>to</span>
            <Calendar
              className={classes.calendarIcon2}
              color={calendarIcon2Color || '2C313A'}
            />
            <input
              className={classes.dateInput}
              ref={endProps.inputRef}
              {...endProps.inputProps}
              value={moment(endProps.inputProps.value).format('MMM DD, YYYY')}
              onMouseEnter={() => setCalendarIcon2Color('5E59F3')}
              onMouseLeave={() => setCalendarIcon2Color(null)}
              onFocusCapture={() => setCalendarIcon2Color(null)}
            />
          </div>
        )}
      />
    </LocalizationProvider>
  );
});

export default CustomDateRangePicker;
