/* eslint-disable prettier/prettier */
import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAsync } from 'react-use';
import { Line } from 'react-chartjs-2';
import { Link, NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { makeStyles, Button, Box } from '@material-ui/core';

import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { capitalize } from 'lodash';

import Page from '~/components/shared/Page';
import { authRoles } from '~/constants';
import {
  getAllCompaniesStats,
  getCompanyOverallStats,
  getCompanyViewsChartStats,
  getAllCompaniesViewsChartStats,
  getExplorers3D,
} from '~/services/api';

import { setCompanyStats, setLoading, setError } from '~/store/companyStats';
import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';

import { setInitialRange, trySetRange } from '~/store/datePicker';
import { formatDateFromSeconds } from '~/utils/helper';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';
import {
  calculateEventsInIntervals,
  filterStatsByDate,
  replaceUnderscoreWithSpace,
  convertUnicodeString,
} from '~/views/private/overview/utils';
import { dateConverter } from '~/views/private/billings/utils';

import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import { Download } from '~/views/private/overview/Components/icons/Download';
import { CardStat } from '~/views/private/overview/Components/CardStat';
import ImageWithFallback from '~/components/shared/ImageWithFallback';
import { TimeStep, MultiDateRangePicker, GraphTooltip } from './Components';
import { TOOLTIP_TYPE } from './constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: 'var(--utilities-size-1-500)',
  },
  tourCardContainer: {
    display: 'flex',
    padding: '6px',
    paddingTop: '0',
    cursor: 'pointer',
    justifyContent: 'center',
    marginBottom: '10px',
    '&:hover $multilocationName': {
      color: 'var(--surface-accent)',
    },
  },
  tourCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  popularImgContainer: {
    borderRadius: 'var(--utilities-border-radius-md)',
    paddingBottom: 'var(--utilities-size-0-500)',
  },
  upload: {
    background: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    padding: '4px 6px',
    width: 'var(--utilities-size-1-750)',
    display: 'flex',
    marginLeft: 6,
    height: 'var(--utilities-size-1-500)',
    cursor: 'pointer',
    border: '1px solid var(--surface-tint-strong)',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 'var(--utilities-size-1-500)',
  },
  mainContainer: {
    minHeight: '100%',
  },
  firstColumnContainer: {
    height: '100%',
  },
  header: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontSize: 'var(--typography-font-size-xl)',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    color: 'var(--content-primary)',
  },
  header2: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontSize: 'var(--typography-font-size-sm)',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-paragraph)',
    color: 'var(--content-secondary)',
  },
  mostPopularTours: {
    padding: 'var(--utilities-size-0-750) var(--utilities-size-1-250)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    flexDirection: 'row',
  },
  mostPopularToursHeader: {
    fontFamily: 'var(--typography-font-family-default) !important',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontStyle: 'normal',
    fontSize: 'var(--typography-font-size-md)',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    color: 'var(--content-primary)',
    paddingBottom: 'var(--utilities-size-1-000)',
  },
  popularImg: {
    width: '324px',
    height: '120px',
    borderRadius: 'var(--utilities-border-radius-md)',
    boxShadow: 'inset 0px 4px 10px rgba(0, 0, 0, 0.5)',
    objectFit: 'cover',
  },
  multilocationName: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontSize: 'var(--typography-font-size-md)',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    color: 'var(--content-primary)',
    paddingBottom: 'var(--utilities-size-0-500)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '324px',
  },
  statsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  viewsStats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    borderRight: '1px solid var(--border-default)',
    paddingRight: 'var(--utilities-size-1-500)',
  },
  statsTypeHeader: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontSize: 'var(--typography-font-size-2xs)',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-2xs-line)',
    color: 'var(--content-secondary)',
  },
  statsValue: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontSize: 'var(--typography-font-size-sm)',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-sm-line)',
    color: 'var(--content-primary)',
  },
  viewDurationCanvasContainer: {
    height: '280px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  viewsCanvasContainer: {
    height: '330px',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  paymentContainer: {
    alignItems: 'flex-start',
    backgroundColor: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: 'var(--utilities-size-1-000)',
    height: '100%',
  },
  div: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    gap: 'var(--utilities-size-1-500)',
    overflow: 'hidden',
    padding: 'var(--utilities-size-1-000)',
    paddingTop: '0',
    position: 'relative',
    width: '100%',
  },
  textWrapper: {
    alignSelf: 'stretch',
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-md-line)',
    marginTop: '-1px',
    position: 'relative',
  },
  div2: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  table: {
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: 'var(--border-rude-weak)',
    display: 'flex',
    flex: '0 0 auto',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-none)',
    position: 'relative',
    width: '100%',
  },
  table2: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    display: 'flex',
    flex: '0 0 auto',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-none)',
    position: 'relative',
    width: '100%',
  },
  textWrapper2: {
    color: 'var(--content-secondary)',
    flex: 1,
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
  },
  div3: {
    alignItems: 'center',
    display: 'flex',
    gap: 'var(--utilities-size-0-250)',
    position: 'relative',
    width: '210px',
  },
  textWrapper3: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  leadIcon2: {
    height: 'var(--utilities-size-1-250) !important',
    position: 'relative !important',
    width: 'var(--utilities-size-1-250) !important',
  },
  textWrapper4: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    textAlign: 'right',
    width: 'var(--utilities-size-7-500)',
  },
  div4: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: 'var(--border-default)',
    display: 'flex',
    flex: '0 0 auto',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-border-radius-none)',
    position: 'relative',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'var(--surface-hover-default-hover)',
    },
  },
  noBorder: {
    borderBottomWidth: 0,
  },
  textWrapper5: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    textAlign: 'right',
    width: 'var(--utilities-size-1-500)',
  },
  textWrapper6: {
    color: 'var(--content-primary)',
    flex: 1,
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
  },
  textWrapper7: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    width: '210px',
  },
  textWrapper8: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    textAlign: 'right',
    width: 'var(--utilities-size-7-500)',
  },
  textWrapper11: {
    color: 'var(--content-primary-weak)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    position: 'relative',
    width: 'var(--utilities-size-7-500)',
  },
  div5: {
    alignItems: 'center',
    alignSelf: 'stretch',
    borderColor: 'var(--border-default)',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default)',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    display: 'flex',
    flex: '0 0 auto',
    gap: 'var(--utilities-size-0-250)',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 'var(--utilities-size-3-500)',
    '&:hover': {
      backgroundColor: 'var(--surface-hover-default-hover)',
      cursor: 'pointer',
    },
    '&:hover $textWrapper12': {
      color: 'var(--brand-primary)',
    },
    '&:hover $leadIcon2': {
      color: 'var(--brand-primary)',
    },
  },
  textWrapper12: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-md-line)',
    marginTop: '-1px',
    position: 'relative',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  redText: {
    color: 'var(--content-danger)',
  },
  greenText: {
    color: 'var(--content-success)',
  },
  blackText: {
    color: 'var(--content-primary)',
  },
  grayText: {
    color: 'var(--content-primary-weak)',
  },
  reviewAllButton: {
    textTransform: 'none',
    width: '100%',
    '&:hover': {
      backgroundColor: 'var(--surface-hover-default-hover)',
    },
    '&.active': {
      backgroundColor: 'var(--surface-hover-default-hover)',
    },
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  skeletonContent: {
    textAlign: 'center',
    color: 'var(--content-secondary)',
    margin: 'var(--utilities-size-1-250)',
  },
  designComponentInstanceNode: {
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-xs-paragraph)',
    color: 'var(--content-primary)',
  },
  cardStat: {
    flex: '1 !important',
    flexGrow: '1 !important',
    justifyContent: 'center !important',
    width: 'unset !important',
  },
  chartContainer: {
    position: 'relative',
    borderRadius: 'var(--utilities-border-radius-default)',
    padding: '0 24px 20px 20px',
    background: 'var(--surface-default)',
    boxShadow: 'var(--shadows-subtle)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'var(--utilities-size-1-000)',
  },
  chartHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 0 34px 0',
  },
  chartHeader: {
    color: 'var(--content-primary)',
    fontSize: 'var(--typography-font-size-md)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    wordWrap: 'break-word',
  },
  downloadIconContainer: {
    position: 'relative',
    boxShadow: 'var(--shadows-subtle)',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 6px',
    height: 'var(--utilities-size-1-500)',
    marginLeft: 'var(--utilities-size-1-000)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgba(70, 86, 229, 0.4)',
    },
  },
  downloadIcon: {
    position: 'relative',
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    overflow: 'hidden',
    flexShrink: 0,
  },
}));

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(94, 89, 243, 0.6)');
gradient.addColorStop(1, 'rgba(94, 89, 243, 0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradient.addColorStop(0, 'rgba(94, 89, 243, 0.5)');
viewDurationGradient.addColorStop(1, 'rgba(94, 89, 243, 0.25)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 200);
viewDurationGradientHover.addColorStop(0, 'rgba(94, 89, 243, 1)');
viewDurationGradientHover.addColorStop(1, 'rgba(94, 89, 243, 0.49)');

const OverviewView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const refVisits = useRef();
  const secondColRef = useRef();
  const scrollRef = useRef();

  const user = useSelector(state => state.auth.user);

  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;

  const [isTitles, setIsTitles] = useState({});

  const [filteredVisitStats, setFilteredVisitStats] = useState();
  const [isLoad, setIsLoad] = useState({});
  const [arrowIconColor, setArrowIconColor] = useState(null);
  const [downloadIconColor, setDownloadIconColor] = useState(null);
  const [explorers, setExplorers] = useState(null);
  const isAdmin =
    user.role === authRoles.ADMIN || user.role === authRoles.SUPER_ADMIN;

  const stats = useAsync(async () => {
    try {
      if (isAdmin) return await getAllCompaniesStats();
      return await getCompanyOverallStats(user.company);
    } catch (error) {
      console.error('overview:stats', error);
      dispatch(setError(error.message));
    }
  });

  const explorers3D = useAsync(async () => {
    return getExplorers3D();
  });

  const visitStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return !isAdmin
          ? await getCompanyViewsChartStats(user.company)
          : await getAllCompaniesViewsChartStats();
      }
    } catch (e) {
      console.error('overview:visitStats', e);
      return [];
    }
  }, [stats.loading]);

  const changeTimestampHandler = useCallback(() => {
    scrollRef.current.scrollLeft = 0;
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      const { scrollWidth } = scrollRef.current;
      const { clientWidth } = scrollRef.current;

      scrollRef.current.scrollLeft = scrollWidth - clientWidth;
    }
  }, [filteredVisitStats]);

  useEffect(() => {
    if (!explorers3D.loading && explorers3D.value) {
      setExplorers(explorers3D.value);
    }
  }, [explorers3D]);

  useEffect(() => {
    if (!isTitles.chart) return;
    const { canvas, chart } = isTitles;
    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);
      const maxWidth = 750;
      const maxHeight = 500;
      const ratioOfList = 210 / 297;
      const ratio = canvas.height / canvas.width;
      const docDefinition =
        ratio < ratioOfList
          ? {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxWidth,
                  height: maxWidth * ratio,
                },
              ],
              styles: {
                header: {
                  fontSize: 35,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            }
          : {
              content: [
                {
                  text: chart,
                  style: 'header',
                },
                {
                  image: imgData,
                  width: maxHeight / ratio,
                  height: maxHeight,
                },
              ],
              styles: {
                header: {
                  fontSize: 40,
                  bold: true,
                },
              },
              pageOrientation: 'landscape',
            };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    if (!visitStats.loading && visitStats.value && visitsDateValue)
      setFilteredVisitStats(
        filterStatsByDate(visitStats.value, visitsDateValue).slice(),
      );
  }, [visitStats, visitsDateValue]);

  useEffect(() => {
    if (!stats.loading && !visitStats.loading && visitStats.value) {
      dispatch(trySetRange(defaultTimeRange()));
      dispatch(setInitialRange(defaultTimeRange()));
    }
  }, [dispatch, stats.loading, visitStats]);

  useEffect(() => {
    if (visitStats.value != null && visitsDateValue) {
      const dataVisits = calculateEventsInIntervals({
        data: visitStats.value.slice().map(({ eventDate, eventsCount }) => {
          return {
            eventDate,
            value: eventsCount,
          };
        }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredVisitStats(
        dataVisits.reverse().map(({ eventDate, value }) => ({
          eventDate,
          eventsCount: value,
        })),
      );
    }
  }, [currentStep, visitStats, visitsDateValue]);

  const getStatusColorClass = status => {
    switch (status) {
      case 'past due':
      case 'pending':
        return 'redText';
      case 'trialling':
      case 'active':
        return 'greenText';
      case 'paused':
      case 'canceled':
        return 'blackText';
      default:
        return 'grayText';
    }
  };

  const calculateTotal = count => {
    return Object.values(count).reduce((sum, count) => sum + count, 0);
  };

  const expandPropertyStatsWithExplorers3D = (
    sortedPropertyStats,
    explorers3D,
  ) => {
    return sortedPropertyStats.map(property => {
      const matchedExplorers =
        explorers3D &&
        explorers3D.filter(
          explorer => explorer.multilocationId === property.property_id,
        );
      return {
        ...property,
        explorers3D: matchedExplorers,
      };
    });
  };

  const calculateSums = !isAdmin
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback(data => {
        if (data.loading) return 0;
        return data.value.reduce(
          (acc, item) => {
            acc.conversion += Object.values(item.conversion || {}).reduce(
              (sum, val) => sum + (val || 0),
              0,
            );

            acc.custom_button_hotspot_clicks += Object.values(
              item.custom_button_hotspot_clicks || {},
            ).reduce((sum, val) => sum + (val || 0), 0);

            acc.duration += Object.values(item.duration || {}).reduce(
              (sum, val) => sum + (val || 0),
              0,
            );

            acc.last_month_views_count += Object.values(
              item.last_month_views_count || {},
            ).reduce((sum, val) => sum + (val || 0), 0);

            acc.views_count += Object.values(item.views_count || {}).reduce(
              (sum, val) => sum + (val || 0),
              0,
            );

            acc.mobile_visits +=
              (item.views_count.location_views_mobile || 0) +
              (item.views_count.location_3d_explorer_views_mobile || 0);

            return acc;
          },
          {
            conversion: 0,
            custom_button_hotspot_clicks: 0,
            duration: 0,
            last_month_views_count: 0,
            views_count: 0,
            mobile_visits: 0,
          },
        );
      }, [])
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useCallback(data => {
        if (data.loading) return 0;
        const initialAccumulator = {
          conversion: 0,
          custom_button_hotspot_clicks: 0,
          duration: 0,
          last_month_views_count: 0,
          views_count: 0,
          mobile_visits: 0,
        };

        return data.value.reduce((companyAcc, company) => {
          return company.stats.reduce((acc, item) => {
            acc.conversion += Object.values(item.conversion || {}).reduce(
              (sum, val) => sum + (val || 0),
              0,
            );

            acc.custom_button_hotspot_clicks += Object.values(
              item.custom_button_hotspot_clicks || {},
            ).reduce((sum, val) => sum + (val || 0), 0);

            acc.duration += Object.values(item.duration || {}).reduce(
              (sum, val) => sum + (val || 0),
              0,
            );

            acc.last_month_views_count += Object.values(
              item.last_month_views_count || {},
            ).reduce((sum, val) => sum + (val || 0), 0);

            acc.views_count += Object.values(item.views_count || {}).reduce(
              (sum, val) => sum + (val || 0),
              0,
            );

            acc.mobile_visits +=
              (item.views_count.location_views_mobile || 0) +
              (item.views_count.location_3d_explorer_views_mobile || 0);

            return acc;
          }, companyAcc);
        }, initialAccumulator);
      }, []);

  const properties = !isAdmin
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useMemo(() => {
        dispatch(setLoading(true));
        if (stats.loading) return [];
        const propertyStats = stats.value
          .map(property => ({
            ...property,
            companyName: user.company,
            totalViews: calculateTotal(property.views_count),
            totalDuration: calculateTotal(property.duration),
            totalConversion: calculateTotal(property.conversion),
          }))
          .sort((a, b) => b.totalViews - a.totalViews);
        const updatedPropertyStats = expandPropertyStatsWithExplorers3D(
          propertyStats,
          explorers,
        );

        dispatch(setCompanyStats(updatedPropertyStats));
        return propertyStats;
      }, [dispatch, user.company, explorers, stats.loading, stats.value])
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useMemo(() => {
        dispatch(setLoading(true));
        if (stats.loading) return [];

        const allPropertyStats = stats.value.flatMap(company =>
          company.stats.map(property => ({
            ...property,
            companyName: company.company,
            totalViews: calculateTotal(property.views_count),
            totalDuration: calculateTotal(property.duration),
            totalConversion: calculateTotal(property.conversion),
          })),
        );

        const sortedPropertyStats = allPropertyStats.sort(
          (a, b) => b.totalViews - a.totalViews,
        );

        const updatedPropertyStats = expandPropertyStatsWithExplorers3D(
          sortedPropertyStats,
          explorers,
        );

        dispatch(setCompanyStats(updatedPropertyStats));
        return updatedPropertyStats;
      }, [dispatch, explorers, stats.loading, stats.value]);

  const companyOverallStats = calculateSums(stats);

  return (
    <Page className={classes.root} title="Overview">
      <Box className={classes.headerContainer}>
        <Box>
          <Box className={classes.header}>Overview</Box>
          <Box className={classes.header2}>Multi-Tour Analytics</Box>
        </Box>
      </Box>
      <Box className={classes.infoCardContainer}>
        <Box className="w-full flex flex-wrap justify-between gap-2">
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Conversion"
            loading={stats.loading}
            tooltipText="Amounts of clicks to the Lease Now button at the bottom right corner"
            value={
              companyOverallStats.conversion !== 0
                ? companyOverallStats.conversion
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Custom Button Clicks"
            loading={stats.loading}
            tooltipText="Amounts of clicks on the all buttons inside informational hotspots"
            value={
              companyOverallStats.custom_button_hotspot_clicks !== 0
                ? companyOverallStats.custom_button_hotspot_clicks
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Views"
            loading={stats.loading}
            value={
              companyOverallStats.views_count !== 0
                ? companyOverallStats.views_count
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Last Month Views"
            loading={stats.loading}
            value={
              companyOverallStats.last_month_views_count !== 0
                ? companyOverallStats.last_month_views_count
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Duration"
            loading={stats.loading}
            value={
              companyOverallStats.duration !== 0
                ? formatDateFromSeconds(companyOverallStats.duration)
                : '00:00'
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="on"
            title="Mobile"
            loading={stats.loading}
            complexTooltip
            tooltipText={`Total views: ${
              stats.value && companyOverallStats.mobile_visits
            }`}
            value={
              (companyOverallStats.mobile_visits /
                companyOverallStats.views_count) *
              100
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="on"
            title="Desktop"
            loading={stats.loading}
            complexTooltip
            tooltipText={`Total views: ${
              stats.value &&
              companyOverallStats.views_count -
                companyOverallStats.mobile_visits
            }`}
            value={
              100 -
              (companyOverallStats.mobile_visits /
                companyOverallStats.views_count) *
                100
            }
          />
        </Box>
      </Box>
      <Box
        className={`w-full flex flex-col lg:flex-row ${classes.mainContainer}`}
      >
        <Box
          className={`w-full lg:w-3/5 lg:pr-4 lg:pt-4 flex flex-col ${classes.firstColumnContainer}`}
        >
          <Box
            className={`w-full bg-white flex-col ${classes.mostPopularTours}`}
          >
            <Box className={classes.mostPopularToursHeader}>
              Most Popular Tours
            </Box>
            {stats.loading ? (
              <Skeleton height={162} />
            ) : (
              <Box className="flex justify-between">
                {properties.slice(0, 3).map((property, index) => {
                  return (
                    property.locations.length !== 0 && (
                      <NavLink
                        to={`/overview/multi/${
                          isAdmin ? property.companyName : user.company
                        }/${property.property_name}`}
                        key={property.property_id}
                        className={`flex w-1/3 ${classes.tourCardContainer}`}
                        style={{
                          paddingRight: index !== 2 ? '10px' : '0',
                          borderRight:
                            index !== 2 ? '1px solid #171C261A' : 'none',
                        }}
                      >
                        <Box className={classes.tourCard}>
                          <Box className={classes.popularImgContainer}>
                            <ImageWithFallback
                              className={classes.popularImg}
                              src={`${process.env.SNOWPACK_PUBLIC_CDN_ENDPOINT}/locations/${property.locations[0].location_id}/preview.jpg`}
                              fallbackSrc={`/static/images/no-img-placeholder.png`}
                              alt="preview"
                              isLoad={isLoad}
                              setIsLoad={() =>
                                setIsLoad(prev => ({
                                  ...prev,
                                  [property.property_id]: true,
                                }))
                              }
                            />
                          </Box>
                          <Box className={classes.multilocationName}>
                            {convertUnicodeString(property.property_name)}
                          </Box>
                          <Box className={classes.statsContainer}>
                            <Box className={classes.viewsStats}>
                              <Box className={classes.statsTypeHeader}>
                                Views
                              </Box>
                              <Box className={classes.statsValue}>
                                {property.totalViews}
                              </Box>
                            </Box>
                            <Box className="ml-2">
                              <Box className={classes.statsTypeHeader}>
                                Duration
                              </Box>
                              <Box className={classes.statsValue}>
                                {formatDateFromSeconds(property.totalDuration)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </NavLink>
                    )
                  );
                })}
              </Box>
            )}
          </Box>

          <Box className={classes.chartContainer}>
            <Box className={classes.chartHeaderContainer}>
              <Box className="flex">
                <Box className={classes.chartHeader}>Views</Box>
                <Box
                  role="button"
                  className={classes.downloadIconContainer}
                  onMouseEnter={() => setDownloadIconColor('5E59F3')}
                  onMouseLeave={() => setDownloadIconColor(null)}
                  onClick={() =>
                    setIsTitles({
                      canvas: refVisits.current.chartInstance.canvas,
                      chart: 'Views',
                    })
                  }
                >
                  <Download
                    className={classes.downloadIcon}
                    color={downloadIconColor || '2C313A'}
                  />
                </Box>
                <Box>
                  <TimeStep
                    onClick={changeTimestampHandler}
                    visitsDateValue={visitsDateValue}
                  />
                </Box>
              </Box>
              <MultiDateRangePicker />
            </Box>
            {!filteredVisitStats ? (
              <Skeleton height={162} />
            ) : (
              <Box
                className={classes.viewDurationCanvasContainer}
                ref={scrollRef}
              >
                <Box
                  style={{
                    position: 'relative',
                    height: '98%',
                    maxWidth:
                      filteredVisitStats.length < 3
                        ? 'calc(100% - 5px)'
                        : 'none',
                    minWidth: filteredVisitStats.length * size(16),
                    overflowY: 'overlay',
                  }}
                >
                  <Line
                    ref={refVisits}
                    data={{
                      datasets: [
                        {
                          backgroundColor: gradient,
                          lineTension: 0.01,
                          borderColor: '#5E59F3',
                          pointBackgroundColor: 'transparent',
                          pointBorderColor: 'transparent',
                          pointRadius: size(15),
                          pointHoverRadius: size(15),
                          borderWidth: 1,
                          data: filteredVisitStats.map(
                            item => item.eventsCount,
                          ),
                          label: '',
                        },
                      ],
                      labels: filteredVisitStats.map(item =>
                        moment(item.eventDate).format('MM.DD'),
                      ),
                    }}
                    options={{
                      animation: false,
                      cornerRadius: size(20),
                      layout: {
                        padding: {
                          right: size(13),
                        },
                      },
                      legend: { display: false },
                      maintainAspectRatio: false,
                      responsive: true,
                      devicePixelRatio: window.devicePixelRatio * 2,
                      onHover(e, [chart]) {
                        if (!chart) {
                          dispatch(
                            updateVisitsTooltipInfo({
                              show: false,
                              showDashes: false,
                              showLabel: false,
                              showCircle: false,
                            }),
                          );
                          return;
                        }
                        const value = filteredVisitStats[chart._index];
                        dispatch(
                          setVisitsTooltipInfo({
                            showCircle: true,
                            showDashes: true,
                            type: TOOLTIP_TYPE.SMALL,
                            show: true,
                            pointId: value.pointId,
                            label: chart._xScale.ticks[chart._index],
                            smallTooltipText: `${value.eventsCount}`,
                            dashesHeight: `${
                              refVisits.current.chartInstance.height - 20
                            }px`,
                            showLabel: true,
                            tooltipLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            tooltipTop: chart._view.y + 45,
                            circleLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              10,
                            circleTop: chart._view.y + 65,
                            dashesLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              20,
                            dashesTop:
                              e.target.parentElement.parentElement.offsetTop,
                            labelLeft:
                              chart._view.x -
                              e.target.parentElement.parentElement.scrollLeft +
                              40,
                            labelBottom: 15,
                          }),
                        );
                      },
                      tooltips: {
                        enabled: false,
                        intersect: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            ticks: {
                              fontSize: size(8),
                              reverse: true,
                            },
                            categoryPercentage: 0.97,
                            barPercentage: 0.97,
                            barThickness: 28,
                            scaleLabel: {
                              display: true,
                              labelString: 'Date',
                            },
                          },
                        ],
                        yAxes: [
                          {
                            ticks: {
                              fontStyle: 'bold',
                              fontColor: '#000',
                              stepSize: 250,
                              beginAtZero: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Views',
                            },
                          },
                        ],
                      },
                    }}
                  />
                </Box>
              </Box>
            )}
            <GraphTooltip store="visitsTooltip" />
          </Box>
        </Box>

        <Box
          className={`w-full lg:w-2/5 lg:pt-4 flex flex-col ${classes.paymentContainer}`}
          ref={secondColRef}
        >
          <Box
            className={classes.div}
            style={{ display: stats.loading && 'unset' }}
          >
            <Box className={classes.textWrapper}>Payment Info</Box>
            {stats.loading ? (
              <Skeleton
                style={{ height: '162px', width: '100%', marginTop: '16px' }}
              />
            ) : (
              <Box className={classes.div2}>
                <Box className={classes.table}>
                  <Box className={classes.textWrapper2}>Tour name</Box>
                  <Box className={classes.div3}>
                    <Box className={classes.textWrapper3}>Status</Box>
                  </Box>
                  <Box className={classes.textWrapper4}>Next Billing Date</Box>
                </Box>
                {properties.slice(0, 15).map((property, index) => (
                  <React.Fragment key={property.property_id}>
                    <Box
                      className={`${classes.div4} ${
                        index === 14 ? classes.noBorder : ''
                      }`}
                    >
                      <Box className={classes.textWrapper5}>{index + 1}</Box>
                      <Link
                        className={classes.textWrapper6}
                        id={`payment-status-link-${index}`}
                        to={`/overview/multi/${property.companyName}/${property.property_name}`}
                      >
                        {isAdmin
                          ? capitalize(
                              convertUnicodeString(property.property_name),
                            )
                          : capitalize(
                              decodeURIComponent(property.property_name),
                            )}
                      </Link>
                      <Box
                        className={`${classes.textWrapper7} ${
                          (property.hosting_stripe_subscription_id ||
                            property.hosting_qb_recurring_transaction_id) &&
                          property.hosting_status !== null
                            ? classes[
                                getStatusColorClass(
                                  replaceUnderscoreWithSpace(
                                    property.hosting_status,
                                  ),
                                )
                              ]
                            : classes[getStatusColorClass('no subscription')]
                        }`}
                      >
                        {(property.hosting_stripe_subscription_id ||
                          property.hosting_qb_recurring_transaction_id) &&
                        property.hosting_status !== null
                          ? capitalize(
                              replaceUnderscoreWithSpace(
                                property.hosting_status,
                              ),
                            )
                          : 'No subscription'}
                      </Box>
                      <Box className={classes.textWrapper8}>
                        {(property.hosting_stripe_subscription_id ||
                          property.hosting_qb_recurring_transaction_id) &&
                        property.hosting_next_billing_date !== null
                          ? dateConverter(property.hosting_next_billing_date)
                          : '—'}
                      </Box>
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            )}
          </Box>
          <Box
            className={classes.div5}
            onMouseEnter={() => setArrowIconColor('5E59F3')}
            onMouseLeave={() => setArrowIconColor(null)}
          >
            <NavLink className="w-full" to="/billings">
              <Button className={classes.reviewAllButton} type="button">
                <Box className={classes.textWrapper12}>
                  Review all hosting fees
                </Box>
                <LeadIcon2
                  className={classes.leadIcon2}
                  color={arrowIconColor || '2C313A'}
                />
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default OverviewView;
