/* eslint-disable prettier/prettier */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import { useAsync } from 'react-use';

import moment from 'moment';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cn from 'classnames';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Box, makeStyles, Tooltip } from '@material-ui/core';

import Page from '~/components/shared/Page';
import Header from '~/components/layouts/DashboardLayout/components/Header';
import {
  getLocationViewsChartStats,
  getLocationButtonClicksChartStats,
  getLocationOverallStats,
  getLocationViewDurationChartStats,
  getLocationViewDurationPointChartStats,
  getLocationPointClicksChartStats,
  getLocationHeatmapStats,
} from '~/services/api';

import { formatDateFromSeconds } from '~/utils/helper';
import {
  calculateChartSize,
  calculateEventsInIntervals,
  calculateImgSize,
  filterStatsByDate,
  formatLabels,
  renderDecimal,
  convertUnicodeString,
} from '~/views/private/overview/utils';
import size from '~/utils/size';
import { defaultTimeRange } from '~/utils/defaultTimeRange';
import { CardStat } from '~/views/private/overview/Components/CardStat';
import {
  setInitialRange,
  trySetRange,
  setRange,
  setUseCustomRange,
} from '~/store/datePicker';

import {
  hideDurationTooltip,
  setDurationTooltipInfo,
  updateDurationTooltipInfo,
} from '~/store/durationTooltip';
import {
  hidePointVisitsTooltip,
  setPointsVisitTooltipInfo,
  updatePointsVisitTooltipInfo,
} from '~/store/pointsVisitsTooltip';
import {
  setVisitsTooltipInfo,
  updateVisitsTooltipInfo,
} from '~/store/visitsTooltip';

import { ArrowLeft } from '~/views/private/billings/components/icons/ArrowLeft';
import { Panorama } from '~/views/private/overview/Components/icons/Panorama';
import { LeadIcon2 } from '~/views/private/overview/Components/icons/LeadIcon2';
import { Download } from '~/views/private/overview/Components/icons/Download';
import HeatMap from '~/views/private/overview/Components/HeatMap';
import { MultiDateRangePicker, TimeStep, GraphTooltip } from './Components';
import { GRAPH_TYPE, TOOLTIP_TYPE } from './constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: 'var(--utilities-size-1-500)',
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    padding:
      'var(--utilities-size-none) var(--utilities-size-none) var(--utilities-size-0-750) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-1-000)',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-default)',
  },
  headerTextSection: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-000)',
    flex: '1 0 0',
  },
  headerButtonsSection: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
  },
  backButton: {
    display: 'flex',
    width: '52px',
    height: '52px',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-tint-weak)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-tint-weak-hover)',
    },
  },
  backButtonIcon: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: '1 0 0',
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
  },
  headerTitleText: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xl)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-xl-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  headerSubTitleText: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  virtualTourButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid var(--border-accent-weak)',
    background: 'var(--surface-accent-tint)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid var(--border-hover-accent-weak-hover)',
      background: 'var(--surface-hover-accent-tint-hover)',
    },
  },
  buttonIcon: {
    width: 'var(--utilities-size-1-250)',
    height: 'var(--utilities-size-1-250)',
  },
  virtualTourButtonText: {
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  explorerButton: {
    display: 'flex',
    minHeight: 'var(--utilities-size-2-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    background: 'var(--surface-accent)',
    boxShadow: 'var(--shadows-subtle)',
    cursor: 'pointer',
    '&:hover': {
      background: 'var(--surface-hover-accent-hover)',
    },
  },
  explorerButtonText: {
    color: 'var(--content-inverted)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  leadIcon2: {
    height: 'var(--utilities-size-1-000)',
    width: 'var(--utilities-size-1-000)',
  },
  infoCardContainer: {
    alignSelf: 'stretch',
    paddingTop: 'var(--utilities-size-1-500)',
  },
  infoCardsRow: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
  },
  cardStat: {
    flex: '1 !important',
    flexGrow: '1 !important',
    justifyContent: 'center !important',
    width: 'unset !important',
  },
  designComponentInstanceNode: {
    color: 'var(--content-primary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-lg-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  chartHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 0 20px 0',
  },
  sectionHeader: {
    color: 'var(--content-primary)',
    fontSize: 'var(--typography-font-size-md)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  downloadIconContainer: {
    position: 'relative',
    boxShadow: '0px 1px 2px rgba(23, 28, 38, 0.05)',
    borderRadius: 'var(--utilities-border-radius-default)',
    backgroundColor: 'var(--surface-default)',
    border: '1px solid rgba(23, 28, 38, 0.2)',
    boxSizing: 'border-box',
    overflow: 'hidden',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 6px',
    height: 'var(--utilities-size-1-500)',
    marginLeft: 'var(--utilities-size-1-000)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid rgba(70, 86, 229, 0.4)',
    },
  },
  downloadIcon: {
    position: 'relative',
    width: 'var(--utilities-size-1-000)',
    height: 'var(--utilities-size-1-000)',
    overflow: 'hidden',
    flexShrink: 0,
  },
  viewDurationLocation: {
    display: 'flex',
    minHeight: 'var(--utilities-size-1-500)',
    padding: 'var(--utilities-size-0-250) var(--utilities-size-0-375)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-250)',
    borderRadius:
      'var(--utilities-border-radius-default) var(--utilities-border-radius-none) var(--utilities-border-radius-none) var(--utilities-border-radius-default)',
    background: 'var(--surface-default)',
    color: 'var(--content-primary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    border: '1px solid var(--border-control-default)',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
      color: 'var(--surface-hover-accent-hover)',
    },
    '&.active': {
      background: 'var(--surface-rude)',
      color: 'var(--surface-default)',
      border: '1px solid var(--surface-rude)',
    },
  },
  viewDurationPoint: {
    display: 'flex',
    minHeight: 'var(--utilities-size-1-500)',
    padding: 'var(--utilities-size-0-250) var(--utilities-size-0-375)',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    gap: 'var(--utilities-size-0-250)',
    borderRadius:
      'var(--utilities-border-radius-none) var(--utilities-border-radius-default) var(--utilities-border-radius-default) var(--utilities-border-radius-none)',
    background: 'var(--surface-default)',
    color: 'var(--content-primary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    border: '1px solid var(--border-control-default)',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid rgba(70, 86, 229, 0.4)',
      color: 'var(--surface-hover-accent-hover)',
    },
    '&.active': {
      background: 'var(--surface-rude)',
      color: 'var(--surface-default)',
      border: '1px solid var(--surface-rude)',
    },
  },
  container: {
    height: '370px',
  },
  doughnutHeaderContainer: {
    display: 'flex',
    padding: '18px 0 20px 0',
  },
  heatMapContainer: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-1-500)',
    alignSelf: 'stretch',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-subtle)',
  },
  heatMapContent: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-7-500)',
    alignSelf: 'stretch',
  },
  heatMapTable: {
    display: 'flex',
    width: '500px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 'var(--utilities-size-none)',
    alignSelf: 'stretch',
    maxHeight: '400px',
    overflowY: 'auto',
    paddingRight: 'var(--utilities-size-0-500)',
  },
  heatMapTableRow: {
    display: 'flex',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-none)',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    borderBottom: '1px solid var(--border-rude-weak)',
  },
  heatMapTableCell: {
    color: 'var(--content-secondary)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'var(--typography-line-height-sm-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
  selectEntityContainer: {
    backgroundColor: 'var(--surface-default)',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    width: 'max-content',
    height: 'var(--utilities-size-1-500)',
    borderRadius: 'var(--utilities-border-radius-default)',
    marginLeft: 'var(--utilities-size-1-000)',
  },
  emptyHeatMapTable: {
    display: 'flex',
    padding: 'var(--utilities-size-1-000) var(--utilities-size-none)',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 'var(--utilities-size-0-500)',
    alignSelf: 'stretch',
    color: 'var(--content-secondary)',
    textAlign: 'center',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-md)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-md-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '260px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

const charts = {
  VIEWS: 'Location Views',
  VIEW_DURATION: 'View Duration',
  POINTS_VIEWS: 'Point Views',
};

const ctx = document.createElement('canvas').getContext('2d');
const gradient = ctx.createLinearGradient(0, 0, 0, 250);
gradient.addColorStop(0, 'rgba(94, 89, 243, 0.6)');
gradient.addColorStop(1, 'rgba(94, 89, 243, 0)');

const viewDurationGradient = ctx.createLinearGradient(0, 0, 0, 250);
viewDurationGradient.addColorStop(0, 'rgba(94, 89, 243, 0.5)');
viewDurationGradient.addColorStop(1, 'rgba(94, 89, 243, 0.25)');

const viewDurationGradientHover = ctx.createLinearGradient(0, 0, 0, 250);
viewDurationGradientHover.addColorStop(0, 'rgba(94, 89, 243, 1)');
viewDurationGradientHover.addColorStop(1, 'rgba(94, 89, 243, 0.49)');

const hideTimeout = 0;

const LocationOverviewView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const visitsDateValue = useSelector(state => state.datePicker.range);
  const currentStep = useSelector(state => state.timeStep.value).find(
    ts => ts.isSelected,
  ).name;

  const { locationId } = useParams();

  const refVisits = useRef();
  const refDuration = useRef();
  const refPointVisits = useRef();
  const refDoughnut = useRef();

  const refVisitsScroll = useRef();
  const refDurationScroll = useRef();
  const initialVisitsDateValue = useRef(visitsDateValue);
  const timeout = useRef({
    show: false,
    timeout: undefined,
  });

  const [currentViewDurationEntity, setCurrentViewDurationEntity] = useState(
    GRAPH_TYPE.LOCATION,
  );

  const [isTitles, setIsTitles] = useState({});

  const [viewDurationStats, setViewDurationStats] = useState(null);
  const [filteredVisitStats, setFilteredVisitStats] = useState([]);
  const [filteredViewDurationStats, setFilteredViewDurationStats] = useState({
    [GRAPH_TYPE.LOCATION]: [],
    [GRAPH_TYPE.POINTS]: [],
  });
  const [heatMapStats, setHeatMapStats] = useState(null);
  const [downloadIconColor, setDownloadIconColor] = useState(null);
  const [downloadIconColor2, setDownloadIconColor2] = useState(null);
  const [downloadIconColor3, setDownloadIconColor3] = useState(null);
  const [downloadIconColor4, setDownloadIconColor4] = useState(null);
  const [hoveredVTButton, setHoveredVTButton] = useState(null);

  const [isPdfDownloaded, setIsPdfDownloaded] = useState(true);

  const stats = useAsync(async () => {
    try {
      return await getLocationOverallStats(locationId);
    } catch (error) {
      console.error('location:stats', error);
    }
  }, [locationId]);

  const locationVisitsStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getLocationViewsChartStats(locationId);
      }
    } catch (e) {
      console.error('location:viewsChartStats', e);
      return [];
    }
  }, [stats.loading]);

  const locationViewDurationStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getLocationViewDurationChartStats(locationId);
      }
    } catch (e) {
      console.error('location:viewDurationChartStats', e);
      return [];
    }
  }, [stats.loading]);

  const locationPointsViewDurationStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getLocationViewDurationPointChartStats(locationId);
      }
    } catch (e) {
      console.error('location:viewDurationPointChartStats', e);
      return [];
    }
  }, [stats.loading]);

  const pointClicksStats = useAsync(async () => {
    try {
      if (!stats.loading) {
        return await getLocationPointClicksChartStats(
          locationId,
          visitsDateValue[0].unix() * 1000,
          visitsDateValue[1].unix() * 1000,
        );
      }
    } catch (e) {
      console.error('location:pointClicksChartStats', e);
      return [];
    }
  }, [stats.loading]);

  const locationMenuClickStats = useAsync(async () => {
    try {
      if (
        !stats.loading &&
        visitsDateValue.length === 2 &&
        initialVisitsDateValue.current !== visitsDateValue
      ) {
        return await getLocationButtonClicksChartStats(
          locationId,
          visitsDateValue[0].unix() * 1000,
          visitsDateValue[1].unix() * 1000,
        );
      }
    } catch (e) {
      console.error('location:locationMenuClickStats', e);
      return [];
    }
  }, [stats.loading, visitsDateValue]);

  const locationHeatMapStats = useAsync(async () => {
    try {
      if (!stats.loading && !locationVisitsStats.loading) {
        return await getLocationHeatmapStats(locationId);
      }
    } catch (e) {
      console.error('location:heatMapStats', e);
      return [];
    }
  }, [stats.loading]);

  const filteredPointClicksStats = useMemo(() => {
    if (!Array.isArray(pointClicksStats.value)) return [];

    return [...pointClicksStats.value].sort(
      (a, b) => b.eventsCount - a.eventsCount,
    );
  }, [pointClicksStats.value]);

  const handleSavePdf = useCallback(canvas => {
    const imgData = canvas.toDataURL('image/png', 1);
    const docDefinition = {
      content: [
        {
          text: 'Tours Button Clicks Summary',
          style: 'header',
        },
        {
          text: "Information about the user's actions with the viewed tour",
          fontSize: 10,
          margin: [0, 0, 0, 10],
        },
        calculateImgSize(imgData, canvas.width, canvas.height),
      ],
      styles: {
        header: {
          fontSize: 23,
          bold: true,
        },
      },
      pageOrientation: 'landscape',
    };

    pdfMake.createPdf(docDefinition).download();
  }, []);

  const handleSaveViewDurationInPdf = useCallback((canvas, entity) => {
    return new Promise(rs => {
      const imgDataFirst = canvas.toDataURL('image/png', 1);
      const firstW = canvas.width;
      const firstH = canvas.height;

      setCurrentViewDurationEntity(prev =>
        prev === 'locationPoints' ? 'location' : 'locationPoints',
      );

      setTimeout(() => {
        const imgDataSecond = canvas.toDataURL('image/png', 1);

        const maxHeight = 430;
        const maxWidth = 750;

        const first = calculateImgSize(
          imgDataFirst,
          firstW,
          firstH,
          maxWidth,
          maxHeight,
        );
        const second = calculateImgSize(
          imgDataSecond,
          canvas.width,
          canvas.height,
          maxWidth,
          maxHeight,
        );

        const docDefinition = {
          content: [
            {
              text:
                entity === 'location'
                  ? 'Tour View Duration'
                  : 'Point View Duration',
              style: 'header',
            },
            {
              text:
                entity === 'location'
                  ? '\nThe amount of time users spent on a tour divided by day for the specified period\n'
                  : '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            first,
            {
              text:
                entity === 'location'
                  ? 'Point View Duration'
                  : 'Tour View Duration',
              style: 'header',
              margin: [0, maxHeight - first.height, 0, 0],
            },
            {
              text:
                entity === 'location'
                  ? '\nThe amount of time users spent on the tour divided by point by popularity of the tour point.\n'
                  : '\nThe amount of time users spent on a tour divided by day for the specified period\n',
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            second,
          ],
          styles: {
            header: {
              fontSize: 23,
              bold: true,
            },
          },
          pageOrientation: 'landscape',
        };
        pdfMake.createPdf(docDefinition).download();

        setCurrentViewDurationEntity(prev =>
          prev === 'locationPoints' ? 'location' : 'locationPoints',
        );

        setIsTitles({});
        rs();
      }, 0);
    });
  }, []);

  const changeVisitsTimestampHandler = useCallback(() => {
    refVisitsScroll.current.scrollLeft = 0;
  }, []);

  const changeDurationTimestampHandler = useCallback(() => {
    refDurationScroll.current.scrollLeft = 0;
  }, []);

  const resetDatePicker = useCallback(() => {
    dispatch(setRange(initialVisitsDateValue.current));
    dispatch(setUseCustomRange(false));
  }, [dispatch]);

  useEffect(() => {
    if (refVisitsScroll.current) {
      const { scrollWidth } = refVisitsScroll.current;
      const { clientWidth } = refVisitsScroll.current;

      refVisitsScroll.current.scrollLeft = scrollWidth - clientWidth;
    }
  }, [filteredVisitStats]);

  useEffect(() => {
    if (refDurationScroll.current) {
      const { scrollWidth } = refDurationScroll.current;
      const { clientWidth } = refDurationScroll.current;

      refDurationScroll.current.scrollLeft = scrollWidth - clientWidth;
    }
  }, [filteredViewDurationStats]);

  useEffect(() => {
    resetDatePicker();
  }, [locationId, resetDatePicker]);

  useEffect(() => {
    if (
      !locationVisitsStats.loading &&
      locationVisitsStats.value &&
      locationVisitsStats.value[0]
    ) {
      dispatch(
        setInitialRange(
          defaultTimeRange(moment(locationVisitsStats.value[0].eventDate)),
        ),
      );
      dispatch(
        trySetRange(
          defaultTimeRange(moment(locationVisitsStats.value[0].eventDate)),
        ),
      );
    }
  }, [dispatch, locationVisitsStats]);

  useEffect(() => {
    if (
      !locationVisitsStats.loading &&
      locationVisitsStats.value &&
      visitsDateValue
    ) {
      const stats = filterStatsByDate(
        locationVisitsStats.value,
        visitsDateValue,
      ).slice();
      setFilteredVisitStats(stats);
    }
  }, [locationVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (
      !locationViewDurationStats.loading &&
      locationViewDurationStats.value &&
      visitsDateValue.length === 2 &&
      locationPointsViewDurationStats.value
    ) {
      setViewDurationStats({
        location: filterStatsByDate(
          locationViewDurationStats.value,
          visitsDateValue,
        )
          .map(item => {
            return {
              label: moment(item.eventDate).format('DD.MM'),
              data: item,
            };
          })
          .slice(),
        locationPoints: [...locationPointsViewDurationStats.value]
          .sort((a, b) => b.duration - a.duration)
          .map(item => {
            return {
              label: `#${item.pointId + 1}`,
              data: item,
            };
          })
          .slice()
          .reverse(),
      });
    }
  }, [
    locationPointsViewDurationStats.value,
    locationViewDurationStats.value,
    locationViewDurationStats.loading,
    locationPointsViewDurationStats.loading,
    locationViewDurationStats,
    visitsDateValue,
  ]);

  useEffect(() => {
    if (viewDurationStats && visitsDateValue) {
      const dataViewDuration = calculateEventsInIntervals({
        data: viewDurationStats[GRAPH_TYPE.LOCATION].map(({ data }) => {
          return {
            eventDate: data.eventDate,
            value: data.duration,
          };
        }),
        range: currentStep,
        start: visitsDateValue[0].format('YYYY-MM-DD'),
        end: visitsDateValue[1].format('YYYY-MM-DD'),
      });

      setFilteredViewDurationStats({
        [GRAPH_TYPE.LOCATION]: dataViewDuration
          .reverse()
          .map(({ eventDate, value }) => ({
            label: moment(eventDate).format('DD.MM'),
            data: {
              eventDate,
              duration: value,
            },
          })),
        [GRAPH_TYPE.POINTS]: viewDurationStats[GRAPH_TYPE.POINTS],
      });
    }
  }, [
    currentStep,
    currentViewDurationEntity,
    viewDurationStats,
    visitsDateValue,
  ]);

  useEffect(() => {
    if (locationVisitsStats.value == null || locationVisitsStats.loading) {
      return;
    }

    if (typeof locationVisitsStats.value.map !== 'function') return;

    const dataVisits = calculateEventsInIntervals({
      data: locationVisitsStats.value.map(({ eventDate, eventsCount }) => {
        return {
          eventDate,
          value: eventsCount,
        };
      }),
      range: currentStep,
      start: visitsDateValue[0].format('YYYY-MM-DD'),
      end: visitsDateValue[1].format('YYYY-MM-DD'),
    });

    setFilteredVisitStats(
      dataVisits.reverse().map(({ eventDate, value }) => ({
        eventDate,
        eventsCount: value,
      })),
    );
  }, [currentStep, locationVisitsStats, visitsDateValue]);

  useEffect(() => {
    if (!isTitles.chart || isTitles.chart === charts.VIEW_DURATION) return;
    const { canvas, chart, text } = isTitles;

    setTimeout(() => {
      const imgData = canvas.toDataURL('image/png', 1);

      const docDefinition = {
        content: [
          {
            text: chart,
            style: 'header',
          },
          {
            text,
            fontSize: 10,
            margin: [0, 0, 0, 10],
          },
          calculateImgSize(imgData, canvas.width, canvas.height),
        ],
        styles: {
          header: {
            fontSize: 35,
            bold: true,
          },
        },
        pageOrientation: 'landscape',
      };

      pdfMake.createPdf(docDefinition).download();
      setIsTitles({});
    }, 0);
  }, [isTitles]);

  useEffect(() => {
    async function downloadPdf() {
      if (isTitles.chart !== charts.VIEW_DURATION || !isPdfDownloaded) return;
      const { canvas } = isTitles;
      setIsPdfDownloaded(false);
      await handleSaveViewDurationInPdf(canvas, currentViewDurationEntity);
      setIsPdfDownloaded(true);
    }

    downloadPdf();
  }, [
    currentViewDurationEntity,
    handleSaveViewDurationInPdf,
    isPdfDownloaded,
    isTitles,
  ]);

  useEffect(() => {
    initialVisitsDateValue.current = visitsDateValue;
  }, [visitsDateValue]);

  useEffect(() => {
    if (
      !stats.loading &&
      !locationVisitsStats.loading &&
      locationHeatMapStats.value
    ) {
      setHeatMapStats(locationHeatMapStats.value);
    }
  }, [stats.loading, locationVisitsStats.loading, locationHeatMapStats.value]);

  let locationVisits = {};
  let heatMapData = [];

  if (!stats.loading && !locationVisitsStats.loading && heatMapStats) {
    heatMapData = heatMapStats;
  }

  if (!stats.loading && !locationVisitsStats.loading && heatMapStats) {
    locationVisits = heatMapStats.reduce((acc, item) => {
      const subdivisionCode = item.principalSubdivisionCode.split('-')[1];
      const cityKey = `${item.city}, ${subdivisionCode}`;
      if (!acc[cityKey]) {
        acc[cityKey] = 0;
      }
      acc[cityKey] += item.visitsCount;
      return acc;
    }, {});
  }

  const sortedLocations = Object.entries(locationVisits).sort(
    (a, b) => b[1] - a[1],
  );

  function calculateTotals(data) {
    const result = { ...data };

    if (result.views_count) {
      result.total_views_count = Object.values(result.views_count).reduce(
        (acc, val) => acc + val,
        0,
      );
      result.mobile_visits =
        (result.views_count.location_views_mobile || 0) +
        (result.views_count.location_3d_explorer_views_mobile || 0);
    }

    if (result.last_month_views_count) {
      result.total_last_month_views_count = Object.entries(
        result.last_month_views_count,
      ).reduce((acc, [key, val]) => {
        if (key !== 'last_month_total_views') {
          return acc + val;
        }
        return acc;
      }, 0);
    }

    if (result.duration) {
      result.total_duration = Object.values(result.duration).reduce(
        (acc, val) => acc + val,
        0,
      );
    }

    if (result.conversion) {
      result.total_conversion = Object.values(result.conversion).reduce(
        (acc, val) => acc + val,
        0,
      );
    }

    if (result.custom_button_hotspot_clicks) {
      result.total_custom_button_hotspot_clicks = Object.values(
        result.custom_button_hotspot_clicks,
      ).reduce((acc, val) => acc + val, 0);
    }

    return result;
  }

  const locationOverallStats = useMemo(() => {
    if (stats.loading) return [];
    return calculateTotals(stats.value);
  }, [stats]);

  return (
    <Page className={classes.root} title="Overview">
      <Header>
        <Box className={classes.headerContainer}>
          <Box className={classes.headerTextSection}>
            <Box className={classes.backButton} onClick={() => navigate(-1)}>
              <ArrowLeft className={classes.backButtonIcon} />
            </Box>
            <Box className={classes.headerTitleContainer}>
              <Box className={classes.headerTitle}>
                <Box className={classes.headerTitleText}>
                  {stats.loading ? (
                    <Skeleton height="100%" width={200} />
                  ) : (
                    <Link
                      to={`/overview/multi/${locationOverallStats.company_name}/${locationOverallStats.property_name}`}
                    >
                      {convertUnicodeString(locationOverallStats.property_name)}
                    </Link>
                  )}
                </Box>
                <LeadIcon2 className={classes.leadIcon2} />
                {stats.loading ? (
                  <Skeleton
                    style={{
                      height: '24px',
                      width: '200px',
                      marginBottom: '4px',
                    }}
                  />
                ) : (
                  <Box
                    className={classes.headerTitleText}
                    style={{ color: 'var(--content-primary)' }}
                  >
                    {convertUnicodeString(locationOverallStats.location_name)}
                  </Box>
                )}
              </Box>
              <Box className={classes.headerSubTitleText}>
                Individual location statistics within the Virtual Tour
              </Box>
            </Box>
          </Box>
          <Box className={classes.headerButtonsSection}>
            <a
              href={`${process.env.SNOWPACK_PUBLIC_PLAYER_URL}/?locationId=${locationId}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e => e.stopPropagation()}
            >
              <Box
                className={classes.virtualTourButton}
                onMouseEnter={() => setHoveredVTButton(true)}
                onMouseLeave={() => setHoveredVTButton(null)}
              >
                <Panorama
                  className={classes.buttonIcon}
                  color={
                    hoveredVTButton
                      ? 'var(--content-hover-accent-hover)'
                      : 'var(--content-accent)'
                  }
                />
                <Box
                  className={classes.virtualTourButtonText}
                  style={{
                    color: hoveredVTButton
                      ? 'var(--content-hover-accent-hover)'
                      : 'var(--content-accent)',
                  }}
                >
                  Virtual Tour
                </Box>
              </Box>
            </a>
          </Box>
        </Box>
      </Header>

      <Box className={classes.infoCardContainer}>
        <Box className={classes.infoCardsRow}>
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Total Views"
            loading={stats.loading}
            value={
              locationOverallStats.total_views_count !== 0
                ? locationOverallStats.total_views_count
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Last Month Views"
            loading={stats.loading}
            value={
              locationOverallStats.total_last_month_views_count !== 0
                ? locationOverallStats.total_last_month_views_count
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Avg. Views Per Day"
            tooltipText="Average Views Per Day"
            loading={stats.loading}
            value={
              locationOverallStats.average_visits_per_day !== 0
                ? renderDecimal(locationOverallStats.average_visits_per_day)
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Avg. Views Per Month"
            tooltipText="Average Views Per Month"
            loading={stats.loading}
            value={
              locationOverallStats.average_visits_per_month !== 0
                ? renderDecimal(locationOverallStats.average_visits_per_month)
                : -1
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Total Duration"
            loading={stats.loading}
            value={
              locationOverallStats.total_duration !== 0
                ? formatDateFromSeconds(locationOverallStats.total_duration)
                : '00:00'
            }
            tooltipText="Total Duration of viewing of the Virtual Tour"
          />
        </Box>
      </Box>
      <Box className={classes.infoCardContainer} style={{ paddingTop: '8px' }}>
        <Box className={classes.infoCardsRow}>
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Avg. View Duration"
            tooltipText="Average View Duration"
            loading={stats.loading}
            value={
              locationOverallStats.average_visit_duration !== 0
                ? formatDateFromSeconds(
                    locationOverallStats.average_visit_duration,
                  )
                : '00:00'
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Conversion"
            loading={stats.loading}
            value={
              locationOverallStats.total_conversion !== 0
                ? locationOverallStats.total_conversion
                : -1
            }
            tooltipText="Amounts of clicks to Lease Now button at the bottom right corner"
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            icon="off"
            title="Custom Button Clicks"
            loading={stats.loading}
            value={
              locationOverallStats.total_custom_button_hotspot_clicks !== 0
                ? locationOverallStats.total_custom_button_hotspot_clicks
                : -1
            }
            tooltipText="Amounts of clicks on the all buttons inside informational hotspots"
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            loading={stats.loading}
            icon="on"
            title="Mobile"
            complexTooltip
            tooltipText={`Total views: ${
              stats.value && locationOverallStats.mobile_visits
            }`}
            value={
              (locationOverallStats.mobile_visits /
                locationOverallStats.total_views_count) *
              100
            }
          />
          <CardStat
            className={classes.cardStat}
            frameClassName={classes.designComponentInstanceNode}
            loading={stats.loading}
            icon="on"
            title="Desktop"
            complexTooltip
            tooltipText={`Total views: ${
              stats.value &&
              locationOverallStats.total_views_count -
                locationOverallStats.mobile_visits
            }`}
            value={
              100 -
              (locationOverallStats.mobile_visits /
                locationOverallStats.total_views_count) *
                100
            }
          />
        </Box>
      </Box>

      <Box
        className="w-full flex mt-2 mb-2"
        style={{ borderRadius: '8px', minHeight: '273px' }}
      >
        <Box
          className="w-full h-auto bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '8px',
            boxShadow: 'var(var(--shadows-subtle))',
          }}
        >
          <Box className={classes.chartHeaderContainer}>
            <Box className="flex">
              <Tooltip
                placement="top"
                title="Amount of views applied range of times"
                classes={tooltipClass}
              >
                <Box className={classes.sectionHeader}>Location Views</Box>
              </Tooltip>
              <Box
                className={classes.downloadIconContainer}
                onMouseEnter={() => setDownloadIconColor('5E59F3')}
                onMouseLeave={() => setDownloadIconColor(null)}
                onClick={() =>
                  setIsTitles({
                    canvas: refVisits.current.chartInstance.canvas,
                    chart: charts.VIEWS,
                    text: '\nTotal number of views for the specified period',
                  })
                }
              >
                <Download
                  className={classes.downloadIcon}
                  color={downloadIconColor || '2C313A'}
                />
              </Box>
              <Box className="flex">
                <TimeStep
                  visitsDateValue={visitsDateValue}
                  onClick={changeVisitsTimestampHandler}
                />
              </Box>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '1rem',
              }}
            />
            <MultiDateRangePicker />
          </Box>
          <Box
            className={`w-full relative flex flex-col ${classes.container}`}
            ref={refVisitsScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <Box
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  filteredVisitStats.length * calculateChartSize(currentStep),
                overflowY: 'overlay',
              }}
            >
              <Line
                ref={refVisits}
                data={{
                  datasets: [
                    {
                      backgroundColor: gradient,
                      lineTension: 0.01,
                      borderColor: '#5E59F3',
                      pointBackgroundColor: 'transparent',
                      pointBorderColor: 'transparent',
                      pointRadius: size(15),
                      pointHoverRadius: size(15),
                      borderWidth: 1,
                      data: filteredVisitStats.map(item => item.eventsCount),
                      label: '',
                    },
                  ],
                  labels: formatLabels(filteredVisitStats, currentStep),
                }}
                options={{
                  animation: false,
                  cornerRadius: size(20),
                  layout: {
                    padding: {
                      right: size(13),
                    },
                  },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  hover: {
                    onHover(e, [chart]) {
                      if (!chart) {
                        dispatch(
                          updateVisitsTooltipInfo({
                            show: false,
                            showDashes: false,
                            showLabel: false,
                            showCircle: false,
                          }),
                        );
                        return;
                      }

                      const value = filteredVisitStats[chart._index];
                      dispatch(
                        setVisitsTooltipInfo({
                          locationId,
                          showCircle: true,
                          showDashes: true,
                          locationName: convertUnicodeString(
                            locationOverallStats.location_name,
                          ),
                          type: TOOLTIP_TYPE.SMALL,
                          show: true,
                          pointId: value.pointId,
                          label: chart._xScale.ticks[chart._index],
                          smallTooltipText: `${value.eventsCount}`,
                          dashesHeight: `${
                            refVisits.current.chartInstance.height - 20
                          }px`,
                          showLabel: true,
                          tooltipLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            20,
                          tooltipTop: chart._view.y + 28,
                          circleLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            10,
                          circleTop: chart._view.y + 50,
                          dashesLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            20,
                          dashesTop:
                            e.target.parentElement.parentElement.offsetTop,
                          labelLeft:
                            chart._view.x -
                            e.target.parentElement.parentElement.scrollLeft +
                            20,
                          labelBottom: 15,
                        }),
                      );
                    },
                  },
                  tooltips: {
                    enabled: false,
                    intersect: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: size(8),
                          reverse: true,
                        },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 28,
                        scaleLabel: {
                          display: true,
                          labelString: 'Date',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 250,
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Views',
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Box>
          <GraphTooltip store="visitsTooltip" />
        </Box>
      </Box>

      <Box
        className="w-full h-auto mb-2 bg-white relative flex"
        style={{
          padding: '0 20px 10px',
          borderRadius: '8px',
          boxShadow: 'var(--shadows-subtle)',
        }}
      >
        <Box
          className="w-full"
          style={{ visibility: isPdfDownloaded ? 'visible' : 'hidden' }}
        >
          <Box className="flex flex-wrap" style={{ marginTop: 18 }}>
            <Box className="flex" style={{ marginBottom: 18 }}>
              <Box className={classes.sectionHeader}>View Duration</Box>
              <Box
                className={classes.downloadIconContainer}
                onMouseEnter={() => setDownloadIconColor2('5E59F3')}
                onMouseLeave={() => setDownloadIconColor2(null)}
                onClick={() =>
                  setIsTitles({
                    canvas: refDuration.current.chartInstance.canvas,
                    chart: charts.VIEW_DURATION,
                    text: 'The amount of time users spent on a tour divided by day for the specified period',
                  })
                }
              >
                <Download
                  className={classes.downloadIcon}
                  color={downloadIconColor2 || '2C313A'}
                />
              </Box>
              <Box className={classes.selectEntityContainer}>
                <Tooltip
                  placement="top"
                  title="The amount of time the tour was viewed per day"
                  classes={tooltipClass}
                >
                  <Box
                    className={cn({
                      [classes.viewDurationLocation]: true,
                      active: currentViewDurationEntity === GRAPH_TYPE.LOCATION,
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentViewDurationEntity(GRAPH_TYPE.LOCATION);
                    }}
                  >
                    Location
                  </Box>
                </Tooltip>
                <Tooltip
                  placement="top"
                  title="The amount of time each 360 point was viewed"
                  classes={tooltipClass}
                >
                  <Box
                    className={cn({
                      [classes.viewDurationPoint]: true,
                      active: currentViewDurationEntity === 'locationPoints',
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      setCurrentViewDurationEntity('locationPoints');
                    }}
                  >
                    Point
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            {currentViewDurationEntity === 'location' && (
              <Box className="flex">
                <TimeStep
                  visitsDateValue={visitsDateValue}
                  onClick={changeDurationTimestampHandler}
                />
              </Box>
            )}
          </Box>

          <Box
            className={`relative ${classes.container}`}
            ref={refDurationScroll}
            style={{
              overflow: 'auto',
            }}
          >
            <Box
              style={{
                position: 'relative',
                height: '100%',
                minWidth:
                  currentViewDurationEntity === 'location'
                    ? filteredViewDurationStats[currentViewDurationEntity]
                        .length * calculateChartSize(currentStep)
                    : filteredViewDurationStats[currentViewDurationEntity]
                        .length * 32,
                overflowY: 'overlay',
              }}
            >
              <Bar
                ref={refDuration}
                data={{
                  labels: filteredViewDurationStats[
                    currentViewDurationEntity
                  ].map(x => x.label),
                  datasets: [
                    {
                      backgroundColor: viewDurationGradient,
                      hoverBackgroundColor: viewDurationGradientHover,
                      data: filteredViewDurationStats[
                        currentViewDurationEntity
                      ].map(x => Math.round(x.data.duration / 60)),
                      label: '',
                      datasetLabel: '',
                    },
                  ],
                }}
                options={{
                  animation: false,
                  cornerRadius: 20,
                  layout: { padding: 0 },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 1.5,

                  onHover(e, [chart]) {
                    if (!chart) {
                      if (currentViewDurationEntity === GRAPH_TYPE.POINTS) {
                        if (timeout.current.show) {
                          clearTimeout(timeout.current);

                          timeout.current.timeout = setTimeout(() => {
                            dispatch(hideDurationTooltip());
                            timeout.current.show = false;
                          }, hideTimeout);
                        }
                      } else {
                        dispatch(hideDurationTooltip());
                      }

                      return;
                    }

                    const value =
                      filteredViewDurationStats[currentViewDurationEntity][
                        chart._index
                      ].data;

                    dispatch(
                      setDurationTooltipInfo({
                        locationId,
                        locationName: convertUnicodeString(
                          locationOverallStats.location_name,
                        ),
                        show: true,
                        bigTooltipText: `Duration: ${moment
                          .utc(value.duration * 1000)
                          .format('HH:mm:ss')}`,
                        type:
                          currentViewDurationEntity === GRAPH_TYPE.LOCATION
                            ? TOOLTIP_TYPE.SMALL
                            : TOOLTIP_TYPE.BIG,
                        pointId: value.pointId,
                        smallTooltipText: `${Math.round(value.duration / 60)}m`,
                        tooltipTop:
                          currentViewDurationEntity === GRAPH_TYPE.LOCATION
                            ? chart._view.y +
                              e.target.parentElement.parentElement.offsetTop -
                              30
                            : chart._view.y + 63,
                        tooltipLeft:
                          chart._view.x -
                          e.target.parentElement.parentElement.scrollLeft +
                          20,
                      }),
                    );
                    timeout.current.show = true;
                  },
                  tooltips: {
                    enabled: false,
                    intersect: false,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: size(8),
                          reverse: true,
                        },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness:
                          currentViewDurationEntity === 'location'
                            ? calculateChartSize(currentStep) - 2
                            : 30,
                        scaleLabel: {
                          display: true,
                          labelString:
                            currentViewDurationEntity === 'location'
                              ? 'Date'
                              : 'Point',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 250,
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Time (m)',
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Box>
          <GraphTooltip
            store="durationTooltip"
            updateFunc={updateDurationTooltipInfo}
            hideFunc={hideDurationTooltip}
          />
        </Box>
        {!isPdfDownloaded && (
          <Skeleton
            height="100%"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              borderRadius: '10px',
            }}
          />
        )}
      </Box>

      <Box
        className="w-full flex gap-2"
        style={{ borderRadius: '8px', minHeight: '273px' }}
      >
        <Box
          className="w-1/2 h-auto bg-white relative flex flex-col"
          style={{
            padding: '0 20px 10px',
            borderRadius: '8px',
            boxShadow: 'var(--shadows-subtle)',
          }}
        >
          <Box className={classes.chartHeaderContainer}>
            <Box className="flex">
              <Tooltip
                placement="top"
                title="The amount of views of each individual 360 point"
                classes={tooltipClass}
              >
                <Box className={classes.sectionHeader}>Point Views</Box>
              </Tooltip>
              <Box
                className={classes.downloadIconContainer}
                onMouseEnter={() => setDownloadIconColor3('5E59F3')}
                onMouseLeave={() => setDownloadIconColor3(null)}
                onClick={() =>
                  setIsTitles({
                    canvas: refPointVisits.current.chartInstance.canvas,
                    chart: charts.POINTS_VIEWS,
                    text: '\nThe number of views to the tour points divided by the number of point views',
                  })
                }
              >
                <Download
                  className={classes.downloadIcon}
                  color={downloadIconColor3 || '2C313A'}
                />
              </Box>
            </Box>
          </Box>
          <Box
            className={`relative ${classes.container}`}
            style={{ overflow: 'auto' }}
          >
            <Box
              style={{
                position: 'relative',
                height: '100%',
                minWidth: filteredPointClicksStats.length * size(32),
                overflowY: 'overlay',
              }}
            >
              <Bar
                ref={refPointVisits}
                data={{
                  labels: filteredPointClicksStats.map(
                    item => `#${item.pointId + 1}`,
                  ),
                  datasets: [
                    {
                      backgroundColor: viewDurationGradient,
                      hoverBackgroundColor: viewDurationGradientHover,
                      data: filteredPointClicksStats.map(
                        item => item.eventsCount,
                      ),
                      label: '',
                      datasetLabel: '',
                    },
                  ],
                }}
                options={{
                  animation: false,
                  cornerRadius: 20,
                  layout: { padding: 0 },
                  legend: { display: false },
                  maintainAspectRatio: false,
                  responsive: true,
                  devicePixelRatio: window.devicePixelRatio * 2,

                  onHover(e, [chart]) {
                    if (!chart) {
                      if (timeout.current.show) {
                        clearTimeout(timeout.current);

                        timeout.current.timeout = setTimeout(() => {
                          dispatch(hidePointVisitsTooltip());
                          timeout.current.show = false;
                        }, hideTimeout);
                      }

                      return;
                    }

                    const value = filteredPointClicksStats[chart._index];
                    const { data } = filteredViewDurationStats[
                      GRAPH_TYPE.POINTS
                    ].find(item => item.data.pointId === value.pointId);

                    dispatch(
                      setPointsVisitTooltipInfo({
                        locationId,
                        locationName: convertUnicodeString(
                          locationOverallStats.location_name,
                        ),
                        show: true,
                        scrollX:
                          e.target.parentElement.parentElement.scrollLeft,
                        offsetY: e.target.parentElement.parentElement.offsetTop,
                        caretX: chart._view.x,
                        caretY: chart._view.y,
                        pointId: value.pointId,
                        center: true,
                        label: chart._xScale.ticks[chart._index],
                        type: TOOLTIP_TYPE.BIG,
                        bigTooltipText: (
                          // eslint-disable-next-line react/jsx-indent
                          <Box style={{ fontSize: '0.6rem' }}>
                            <Box>Clicks: {value.eventsCount}</Box>
                            <Box>
                              Duration:{' '}
                              {moment.utc(data.duration * 1000).format('HH:mm')}
                            </Box>
                          </Box>
                        ),
                        tooltipTop: chart._view.y + 65,
                        tooltipLeft:
                          chart._view.x -
                          e.target.parentElement.parentElement.scrollLeft +
                          20,
                      }),
                    );
                    timeout.current.show = true;
                  },
                  tooltips: {
                    enabled: false,
                    intersect: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: { fontSize: size(8) },
                        categoryPercentage: 0.97,
                        barPercentage: 0.97,
                        barThickness: 28,
                        scaleLabel: {
                          display: true,
                          labelString: 'Point',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        // afterFit(scaleInstance) {
                        //   setClicksChartScaleWidth(scaleInstance.width);
                        // },
                        ticks: {
                          fontStyle: 'bold',
                          fontColor: '#000',
                          stepSize: 300,
                          beginAtZero: true,
                        },
                        scaleLabel: {
                          display: true,
                          labelString: 'Clicks',
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Box>
          <GraphTooltip
            store="pointsVisitsTooltip"
            hideFunc={hidePointVisitsTooltip}
            updateFunc={updatePointsVisitTooltipInfo}
          />
        </Box>
        <Box
          className="w-1/2 h-auto bg-white relative"
          style={{
            padding: '0 20px 10px',
            borderRadius: '8px',
            boxShadow: 'var(--shadows-subtle)',
          }}
        >
          <Box className={classes.doughnutHeaderContainer}>
            <Box className={classes.sectionHeader}>
              Tours Button Clicks Summary
            </Box>
            <Box
              role="button"
              className={classes.downloadIconContainer}
              onMouseEnter={() => setDownloadIconColor4('5E59F3')}
              onMouseLeave={() => setDownloadIconColor4(null)}
              onClick={() =>
                handleSavePdf(refDoughnut.current.chartInstance.canvas)
              }
            >
              <Download
                className={classes.downloadIcon}
                color={downloadIconColor4 || '2C313A'}
              />
            </Box>
          </Box>
          <Box
            className={`w-full relative flex flex-col ${classes.container}`}
            style={{
              overflow: 'auto',
            }}
          >
            <Box
              style={{
                position: 'relative',
                height: '100%',
                overflowY: 'overlay',
              }}
            >
              <Doughnut
                ref={refDoughnut}
                data={{
                  datasets: [
                    {
                      // fill:'start',
                      backgroundColor: (locationMenuClickStats.value || []).map(
                        x => x.color,
                      ),
                      // backgroundColor: colors.blue[500],
                      data: (locationMenuClickStats.value || []).map(
                        x => x.count,
                      ),
                      datalabels: {
                        labels: {
                          value: {},
                          name: {
                            align: 'end',
                            anchor: 'end',
                            offset: -1,
                            font: { size: 1 },
                            padding: 0,
                            backgroundColor: '#A0A0A0',
                            color: '#A0A0A0',
                            formatter() {
                              return '................................................................................................';
                            },
                            rotation(value) {
                              const sum = value.dataset.data.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue,
                              );
                              const position =
                                value.dataset.data
                                  .slice(0, value.dataIndex + 1)
                                  .reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue,
                                  ) -
                                value.dataset.data[value.dataIndex] / 2;
                              return (position / sum) * 360 - 90;
                            },
                          },
                        },
                      },
                    },
                  ],
                  labels: (locationMenuClickStats.value || []).map(x => x.name),
                }}
                options={{
                  maintainAspectRatio: false,
                  devicePixelRatio: window.devicePixelRatio * 2,
                  legend: {
                    display: true,
                    position: document.body.offsetWidth > 1500 ? 'left' : 'top',
                    labels: {
                      fontSize: size(12),
                    },
                  },
                  layout: {
                    padding: 50,
                  },
                  scales: {
                    xAxes: [{ display: false }],
                    yAxes: [{ display: false }],
                  },
                  pieceLabel: {
                    render: 'label',
                    fontColor: '#000',
                    position: 'outside',
                  },
                  plugins: {
                    datalabels: {
                      anchor: 'end',
                      align: 'end',
                      offset: 22,
                      color: '#A0A0A0',
                      font: {
                        size: size(12),
                      },
                    },
                  },
                }}
                plugins={[
                  ChartDataLabels,
                  {
                    beforeInit(chart) {
                      chart.legend.afterFit = function () {
                        this.height += 50;
                        this.legendHitBoxes.forEach(element => {
                          element.left = 0;
                        });
                      };
                    },
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="w-full flex gap-2 mt-2">
        <Box
          className={`w-full h-auto bg-white relative ${classes.heatMapContainer}`}
        >
          <Box className={classes.sectionHeader}>User Locations</Box>
          <Box className={classes.heatMapContent}>
            <Box className={classes.heatMapTable}>
              <Box className={classes.heatMapTableRow}>
                <Box
                  style={{ flex: '1 0 0' }}
                  className={classes.heatMapTableCell}
                >
                  Region
                </Box>
                <Box
                  style={{
                    width: 'var(--utilities-size-5-000)',
                    textAlign: 'right',
                  }}
                  className={classes.heatMapTableCell}
                >
                  Total visits
                </Box>
              </Box>
              {sortedLocations.length > 0 ? (
                sortedLocations.map(([city, count]) => (
                  <Box
                    key={city}
                    style={{ borderBottom: '1px solid var(--border-default)' }}
                    className={classes.heatMapTableRow}
                  >
                    <Box
                      style={{ flex: '1 0 0', color: 'var(--content-primary)' }}
                      className={classes.heatMapTableCell}
                    >
                      {city}
                    </Box>
                    <Box
                      style={{
                        width: 'var(--utilities-size-5-000)',
                        textAlign: 'right',
                        color: 'var(--content-primary)',
                      }}
                      className={classes.heatMapTableCell}
                    >
                      {count}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box className={classes.emptyHeatMapTable}>Nothing found</Box>
              )}
            </Box>
            {heatMapData && <HeatMap data={heatMapData} />}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default LocationOverviewView;
