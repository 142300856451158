/* eslint-disable prettier/prettier */
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { toLower } from 'lodash';

import { getShortNumber } from '~/utils/getShortNumber';
import getTooltipValue from '~/utils/getTooltipValue';
import toPercent from '~/utils/toPercent';

const useStyles = makeStyles(() => ({
  cardStat: {
    alignItems: 'flex-start',
    backgroundColor: 'var(--surface-default)',
    borderRadius: 'var(--utilities-border-radius-default)',
    border: '1px solid transparent',
    boxShadow: 'var(--shadows-subtle)',
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--utilities-size-0-250)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-1-000)',
    position: 'relative',
    width: '160px',
    height: '68px',
    '&:hover': {
      border: '1px solid var(--surface-hover-tint-strong-hover)',
    },
  },
  title: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    alignSelf: 'stretch',
    color: 'var(--content-secondary)',
    display: '-webkit-box',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-sm)',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-sm-line)',
    marginTop: '-1px',
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
  },
  element: {
    alignSelf: 'stretch',
    position: 'relative',
  },
  text: {
    alignSelf: 'stretch',
    color: 'var(--content-primary)',
    flex: 1,
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-lg)',
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: 'var(--typography-letter-spacing-default)',
    lineHeight: 'var(--typography-line-height-lg-line)',
    marginTop: '-1px',
    position: 'relative',
    whiteSpace: 'nowrap',
    marginLeft: 'var(--utilities-size-0-500)',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  imgContainer: {
    width: 'var(--utilities-size-1-500)',
    height: 'var(--utilities-size-1-500)',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: '240px',
    alignItems: 'center',
    backgroundColor: 'var(--surface-inverted)',
    borderRadius: 'var(--utilities-border-radius-default)',
    boxShadow: 'var(--shadows-popover)',
    display: 'flex',
    gap: 'var(--utilities-size-0-500)',
    padding: 'var(--utilities-size-0-500) var(--utilities-size-0-750)',
    position: 'relative',
    margin: 'var(--utilities-size-0-500)',
    color: 'var(--content-inverted)',
    fontFamily: 'var(--typography-font-family-default) !important',
    fontSize: 'var(--typography-font-size-xs)',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'var(--typography-line-height-xs-line)',
    letterSpacing: 'var(--typography-letter-spacing-default)',
  },
}));

export const CardStat = ({
  loading = false,
  complexTooltip = false,
  icon,
  className,
  title,
  frameClassName,
  value,
  tooltipText,
}) => {
  const classes = useStyles();
  const tooltipClass = useTooltipStyles();
  const tooltip = complexTooltip
    ? tooltipText
    : `${tooltipText || title}: ${getTooltipValue(value)}`;

  return (
    <Tooltip classes={tooltipClass} placement="top" title={tooltip}>
      <Box className={`${classes.cardStat} ${icon} ${className}`}>
        <Box className={classes.title}>{title}</Box>
        <Box className={`${classes.element} ${frameClassName}`}>
          {icon === 'off' && (
            <>
              {loading || ((value !== null || value !== '00:00') && !value) ? (
                <Skeleton height="100%" />
              ) : (
                getShortNumber(value)
              )}
            </>
          )}
          {icon === 'on' && (
            <>
              {loading ? (
                <Skeleton height="100%" />
              ) : (
                <Box className={classes.contentContainer}>
                  <Box className={classes.imgContainer}>
                    <img
                      src={`/static/icons/seenic/${toLower(title)}.svg`}
                      alt={`${title}`}
                    />
                  </Box>
                  <Box className={classes.text}>
                    {Math.round(toPercent(value))}%
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Tooltip>
  );
};

CardStat.propTypes = {
  loading: PropTypes.bool,
  complexTooltip: PropTypes.bool,
  icon: PropTypes.oneOf(['off', 'on']),
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipText: PropTypes.string,
};
